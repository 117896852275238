import moment from 'moment'

import { getAWSFormatDate, getHorizonDay, getKeyFromValue, getPartDayTimeString, getSelectItemKey } from 'helpers'
import { MultiselectItem, NextToken } from 'types/common.types'
import { CalendarRowData, EmployeeStatus, EReservableType, TRules } from 'types/data.types'

import {
    DayStatusType,
    DepartmentIdsFilter,
    StatusDateType,
    OfficeIdsFilter,
    EmailFilter,
    FirstNameFilter,
    LastNameFilter,
    AllDayActions,
    FullNameFilter,
} from './types'

export const LimitByDepartment = 15

type CreateDataType = (
    weeks: Array<string>,
    schedule: Omit<CalendarRowData, 'id' | 'companyID'>,
) => Array<StatusDateType>

type RequestCommonArgs = {
    weekNumber?: number
    startWeekDate?: string
    employeeId: string
    favouriteOfficeId?: string
    statuses?: Array<MultiselectItem>
}

type StatusesFilter = {
    eq?: EmployeeStatus
    ne?: EmployeeStatus
}

export const statusesList = [
    {
        id: EmployeeStatus.REGISTRED,
        name: 'Registered',
    },
    {
        id: EmployeeStatus.UNREGISTRED,
        name: 'Unregistered',
    },
]

//todo: refactor (compare with future item of the week)
export const createData: CreateDataType = (weeks, data) => {
    let dayWorkspace = ''
    let typeOfDay: DayStatusType = 'NOT_SELECTED'
    let repeatDaysAmount = 0
    let isFullDayBooking = false

    const daysAmountCounter = (currentWorkspace: string, type: DayStatusType, isFullCurrentDayBooking: boolean) => {
        if (dayWorkspace === currentWorkspace && typeOfDay === type && isFullDayBooking === isFullCurrentDayBooking) {
            repeatDaysAmount++
        } else {
            typeOfDay = type
            dayWorkspace = currentWorkspace
            repeatDaysAmount = 0
            isFullDayBooking = isFullCurrentDayBooking
        }

        return repeatDaysAmount
    }

    return weeks.map((day) => {
        const employeeBooking = data.BookingsByWeek.items.find(
            (booking) => booking.startTime.includes(day) && booking.reservable,
        )
        const reservable = employeeBooking?.reservable
        const isFullCurrentDayBooking = employeeBooking?.isFullDay === null || Boolean(employeeBooking?.isFullDay)

        const partDayTime = getPartDayTimeString(employeeBooking?.startTime, employeeBooking?.endTime)

        const formatDataForDayStatus = (workplace: string, type: DayStatusType): StatusDateType => ({
            day,
            workplace,
            type,
            bookingId: employeeBooking?.id,
            partDayTime,
            reservableSpaceId: employeeBooking?.reservable?.Space?.id,
            repeatDaysAmount: daysAmountCounter(workplace, type, isFullCurrentDayBooking),
            isFullDayBooking: isFullCurrentDayBooking,
            isRecurringBooking: !!employeeBooking && typeof employeeBooking.BookingRequest?.repeatType === 'string',
        })

        if (employeeBooking?.isTeamEvent) {
            return formatDataForDayStatus(reservable?.Space?.name || 'team event', 'TEAM_EVENT')
        }

        switch (reservable?.type) {
            case EReservableType.HOME:
                return formatDataForDayStatus('home', 'HOME')
            case EReservableType.AWAY:
                return formatDataForDayStatus('not available', 'NOT_AVAILABLE')
            case EReservableType.VACATION:
                return formatDataForDayStatus('vacation', 'VACATION')
            case EReservableType.BUSINESS_TRIP:
                return formatDataForDayStatus('business trip', 'BUSINESS_TRIP')
            case EReservableType.SICK_LEAVE:
                return formatDataForDayStatus('sick leave', 'SICK_LEAVE')
            case EReservableType.SEAT:
                return formatDataForDayStatus(reservable?.Space?.name || 'office', 'OFFICE')
            default:
                return formatDataForDayStatus('not selected', 'NOT_SELECTED')
        }
    })
}

const getDayStatus = (today: StatusDateType, day?: StatusDateType) => {
    if (day === undefined) {
        return {
            waitingStatusBorder: false,
            isItemEqual: false,
        }
    }

    return {
        waitingStatusBorder: !!today.hasWaitList && !!day.hasWaitList,
        isItemEqual:
            today.workplace === day.workplace &&
            today.type === day.type &&
            today.isFullDayBooking === day.isFullDayBooking,
    }
}

type CheckStatusDaysBordersType = (
    statusDays: Array<StatusDateType>,
    index: number,
) => {
    statusBorder: Array<boolean>
    waitingStatusBorder: Array<boolean>
}

export const checkStatusDaysBorders: CheckStatusDaysBordersType = (statusDays, index) => {
    const currentDay = statusDays[index]

    const { waitingStatusBorder: nextBorder, isItemEqual: isNextEqual } = getDayStatus(
        currentDay,
        statusDays[index + 1],
    )

    const { waitingStatusBorder: prevBorder, isItemEqual: isPrevEqual } = getDayStatus(
        currentDay,
        statusDays[index - 1],
    )

    return {
        statusBorder: [isNextEqual, isPrevEqual],
        waitingStatusBorder: [nextBorder, prevBorder],
    }
}

export const getHorizonOfBooking = (date: string, isManager: boolean, rules?: TRules): string | undefined => {
    if (rules === undefined || isManager) {
        return undefined
    }

    if (
        moment(date).diff(moment(), 'days') >=
        getHorizonDay(rules.horizonOfPlanningTimeAmount, rules.horizonOfPlanningTimeUnit)
    ) {
        return `${rules.horizonOfPlanningTimeAmount} ${getKeyFromValue(rules.horizonOfPlanningTimeUnit).toLowerCase()}`
    }
}

const getStatusesFilters = (statuses) => {
    if (!statuses) {
        return undefined
    }

    let filters: StatusesFilter = {}

    /* we can find in array statuses only REGISTRED or other status */
    statuses.forEach((status) => {
        if (status.id === EmployeeStatus.REGISTRED) {
            filters = { eq: EmployeeStatus.REGISTRED }
        } else {
            filters = { ne: EmployeeStatus.REGISTRED }
        }
    })

    return filters
}

export const setRequestVariablesInSearchEmployees = ({
    weekNumber,
    startWeekDate,
    employeeId,
    favouriteOfficeId,
    filterByNameAndEmail,
    departmentData,
    officeData,
    limit,
    nextToken,
    statuses,
    companyID,
    isManager,
    date,
}: {
    filterByNameAndEmail?: string
    limit?: number
    nextToken?: NextToken
    companyID?: string
    officeData?: Array<MultiselectItem>
    departmentData?: Array<MultiselectItem>
    isManager?: boolean
    date?: string
} & RequestCommonArgs) => {
    let and = [] as Array<{
        or: Array<
            DepartmentIdsFilter | OfficeIdsFilter | FirstNameFilter | LastNameFilter | FullNameFilter | EmailFilter
        >
    }>

    if (departmentData?.length) {
        and = [
            ...and,
            {
                or: departmentData.map((item) => {
                    const departmentId = getSelectItemKey(item, 'id')
                    return { departmentIDsString: { wildcard: `*${departmentId.replace(/-/g, '')}*` } }
                }),
            },
        ]
    }

    if (officeData?.length) {
        and = [...and, { or: officeData.map((item) => ({ favouriteOfficeID: { eq: getSelectItemKey(item, 'id') } })) }]
    }

    if (filterByNameAndEmail) {
        const searchString = filterByNameAndEmail.toLowerCase().replace('@', '').split(' ')
        and = [
            ...and,
            {
                or: [
                    { and: searchString.map((text) => ({ firstname: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ lastname: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ fullNameLowerCase: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ emailForSearch: { wildcard: `${text}*` } })) },
                ],
            },
        ]
    }

    let filterByStatuses = undefined as MultiselectItem[] | undefined

    if (!isManager) {
        filterByStatuses = [statusesList[0]]
    } else if (!filterByNameAndEmail) {
        filterByStatuses = statuses || undefined
    }

    return {
        weekNumber,
        date,
        year: moment(startWeekDate).year(),
        limit: limit || LimitByDepartment,
        nextToken,
        filter: {
            companyID: { eq: companyID },
            id: { ne: employeeId },
            favouriteOfficeID: !favouriteOfficeId ? undefined : { eq: favouriteOfficeId },
            statusString: !filterByStatuses ? undefined : getStatusesFilters(filterByStatuses),
            and: and.length ? and : undefined,
        },
    }
}

export const checkSubscriptionValid = (weekDays: Array<string>, date?: string) => {
    if (date === undefined) {
        return false
    }

    return weekDays.includes(getAWSFormatDate(date))
}

export const getColorByIsPast = (color: string, isPastDay: boolean) => {
    return isPastDay ? `${color}80` : color
}

export const checkCurrentEmployee = (currentEmployeeId: string, id: string) => currentEmployeeId === id

export const getTypeReservableByStatus = (status: AllDayActions) => {
    switch (status) {
        case 'HOME':
            return EReservableType.HOME
        case 'SICK_LEAVE':
            return EReservableType.SICK_LEAVE
        case 'BUSINESS_TRIP':
            return EReservableType.BUSINESS_TRIP
        case 'VACATION':
            return EReservableType.VACATION

        default:
            return EReservableType.AWAY
    }
}

export const isPastFirstRepeatDay = (repeatDaysAmount: number, currentDay: string, day: string) => {
    return moment(day).subtract(repeatDaysAmount, 'days').isBefore(currentDay)
}
