import React, { FC, createContext, useState, useContext, useEffect, useMemo } from 'react'

export enum DeviceSize {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
}

const ResizeContext = createContext<{
    device: DeviceSize | null
    isMobile: boolean
    isDesktop: boolean
    height: number
}>({
    device: null,
    isDesktop: false,
    isMobile: false,
    height: 0,
})

export const ResizeProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [device, setDevice] = useState<DeviceSize | null>(null)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight)

            if (window.innerWidth > 780) {
                setDevice(DeviceSize.DESKTOP)
            } else {
                setDevice(DeviceSize.MOBILE)
            }
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const { isMobile, isDesktop } = useMemo(
        () => ({ isMobile: device === DeviceSize.MOBILE, isDesktop: device === DeviceSize.DESKTOP }),
        [device],
    )

    if (!device) {
        return null
    }

    return <ResizeContext.Provider value={{ device, isMobile, isDesktop, height }}>{children}</ResizeContext.Provider>
}

export const useResizeDevice = () => useContext(ResizeContext)
