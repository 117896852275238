import styled, { css } from 'styled-components'

import { MOBILE_SIDEBAR_HEIHGT } from 'styles/constants'

export const WrapperRoot = styled.div`
    display: flex;
    position: relative;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    & > header {
        position: fixed;
        width: calc(100% - 80px);
        right: 0;
        top: 0;

        div:last-child {
            flex: 0;
        }
    }
`
export const WrapperBackground = styled.div<{ isMobile: boolean }>`
    position: fixed;
    top: 0;
    left: 0;

    background: ${(props) => (props.isMobile ? '#f1f4f7' : '#ffffff')};

    width: 100vw;
    height: 100vh;
`

export const WrapperMain = styled.main<{ hasCustomHeader: boolean; hasSidebar: boolean; isMobile: boolean }>`
    height: 100%;

    flex: 1;
    overflow-x: hidden;
    margin-left: 80px;

    ${(props) =>
        props.isMobile &&
        css`
            margin-left: 0;
            padding-top: ${MOBILE_SIDEBAR_HEIHGT}px;
        `}

    ${(props) =>
        !props.hasSidebar &&
        css`
            margin-left: 0;
            padding-top: 0;
        `}



    ${(props) =>
        props.hasCustomHeader &&
        css`
            margin-top: 120px;
            padding: 0 24px 0 32px;
            height: calc(100% - 120px);
        `}
`
