import { getHoursFromISODateString } from './utils'
import { ExtendedBookingRoomType, FreeTimeline } from '../components/organisms/RoomTimelines/types'
import * as T from '../types/data.types'

import { sortBookingsByStartTime } from './index'

export const timelineHeight = 25

export const getHourFromString = (hour: string) => {
    return +hour.split(':')[0]
}

export const getHoursFromString = (from: string, to: string) => {
    const startHour = +from.split(':')[0]
    const endHour = +to.split(':')[0]

    return [startHour, endHour] as [number, number]
}

/**
 * convert time to index position
 * **/
export const convertTimelineDateToIndexes = (time: string, hoursDiff: number) => {
    const [hours, minutes] = time.split(':')
    const indexForHours = (+hours - hoursDiff) * 2
    // Adjusting minute calculation to be more granular
    const indexForMinutes = +minutes >= 30 ? 1 : 0
    return indexForHours + indexForMinutes
}

/**
 * get hours data
 *
 * - timelinesAmount - amount of timelines (by 30 minutes)
 * - startHour - room opening
 * **/
export const getRoundedRoomHours = (from: string, to: string) => {
    const startTimelineDate = Math.floor(+from.replace(':', '.'))
    const timelinesAmount = (Math.ceil(+to.replace(':', '.')) - startTimelineDate) * 2

    return {
        startHour: startTimelineDate,
        timelinesAmount: timelinesAmount === 48 ? 47 : timelinesAmount,
    }
}

/**
 * get index values by time
 * **/
export const getTimelinePosition = (startTime: string, endTime: string, startHour: number) => {
    const from = getHoursFromISODateString(startTime)
    const to = getHoursFromISODateString(endTime)
    const start = convertTimelineDateToIndexes(from, startHour)

    return { from, to, start, endTimeIsAnotherDay: isDifferentDate(startTime, endTime) }
}

/**
 * get time by index position (time format - HH:mm)
 * **/
export const convertTimeByIndex = (index: number, startRoomHour: number) => {
    const isHour = index % 2 === 0
    const fromHour = startRoomHour + (isHour ? index / 2 : Math.floor(index / 2))
    const fromMinute = isHour ? '00' : '30'
    return fromHour > 9 ? `${fromHour}:${fromMinute}` : `0${fromHour}:${fromMinute}`
}

export const isDifferentDate = (startTime: string, endTime: string): boolean => {
    const [start, end] = [new Date(startTime), new Date(endTime)]
    return (
        start.getFullYear() !== end.getFullYear() ||
        start.getMonth() !== end.getMonth() ||
        start.getDate() !== end.getDate()
    )
}

/**
 * get time values
 *
 * - timelineTime - timeline time (HH:mm — HH:mm)
 * - startTimelineDate - start of timeline (DDThh:mm:ss.SSSZ)
 * - endTimelineDate - end of timeline (DDThh:mm:ss.SSSZ)
 * **/
export const getTimeByPosition = (from: number, to: number, startRoomHour: number, date: string) => {
    const timelineTime = `${convertTimeByIndex(from, startRoomHour)} — ${convertTimeByIndex(to, startRoomHour)}`
    const [startTimelineHour, endTimelineHour] = timelineTime.split(' — ')

    return {
        timelineTime,
        startTimelineDate: `${date}T${startTimelineHour}:00.001Z`,
        endTimelineDate: `${date}T${endTimelineHour}:00.001Z`,
    }
}

/**
 * get timeline height by position (using inside css)
 * **/
const timelineHeightCount = (height: number, start: number, timelineHeightParam) => {
    const heightWithoutBorders = timelineHeightParam * height
    const heightCoefficient = Math.floor((height - (start % 2 === 0 ? 1 : 0)) / 2)

    return heightCoefficient !== 0 ? heightWithoutBorders + heightCoefficient : heightWithoutBorders
}

/**
 * get timeline size
 * **/
export const getTimelineStyles = (height: number, start: number, timelineHeightParam: number = timelineHeight) => ({
    height: timelineHeightCount(height, start, timelineHeightParam),
    top: start * timelineHeightParam + Math.floor(start / 2) + 1,
})

/**
 * get info about free timelines
 *
 * - start - free timeline start position
 * - nextBookingStart - next booking (if exists or or until the end of the room)
 * **/

const sortBookingsRoomsTypeByStartTime = (bookings: Array<ExtendedBookingRoomType>, sortByTime = false) => {
    const compareStartTime = (prev: ExtendedBookingRoomType, next: ExtendedBookingRoomType) => {
        const prevStartTime = sortByTime ? getHoursFromISODateString(prev.time) : prev.time
        const nextStartTime = sortByTime ? getHoursFromISODateString(next.time) : next.time

        if (prevStartTime < nextStartTime) {
            return -1
        }
        if (prevStartTime > nextStartTime) {
            return 1
        }
        return 0
    }

    return [...bookings].sort(compareStartTime)
}

export const getFreeTimelines = (
    timelinesAmount: number,
    bookings: Array<ExtendedBookingRoomType>,
    selectedEventId?: string,
): Array<FreeTimeline> => {
    const timelineAmount = new Array(timelinesAmount).fill(null)

    return timelineAmount.reduce((acc, curr, index) => {
        let bookingExists = false

        const nextBooking = sortBookingsRoomsTypeByStartTime(bookings).find((book) => {
            if (index >= book.start && index < book.height + book.start) {
                bookingExists = true
            }
            return book.start > index
        })

        if (!bookingExists) {
            acc = [
                ...acc,
                {
                    start: index,
                    nextBookingStart: nextBooking === undefined ? timelineAmount.length : nextBooking.start,
                },
            ]
        }

        return acc
    }, [] as Array<FreeTimeline>)
}
