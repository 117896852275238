import { SubscriptionsPlan } from 'types/billing.types'
import { InferActionsTypes } from 'types/common.types'

import { CheckoutDataType, CommonPlanType, PlanStatusType } from './billing.context.types'

const CHANGE_CHECKOUT = 'CHANGE_CHECKOUT'
const CHANGE_CHECKOUT_WITH_PLAN_STATUS = 'CHANGE_CHECKOUT_WITH_PLAN_STATUS'
const CHANGE_PLAN_STATUS = 'CHANGE_PLAN_STATUS'
const CHANGE_SUBSCRIPTION_WINDOW_DISPLAY = 'CHANGE_SUBSCRIPTION_WINDOW_DISPLAY'
const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS'
const SET_PLANS_FOR_COMPANY = 'SET_PLANS_FOR_COMPANY'

export const billingActions = {
    changeCheckout: (payload: CheckoutDataType) => ({ type: CHANGE_CHECKOUT, payload } as const),
    changeCheckoutWithPlanStatus: (payload: { checkout: CheckoutDataType; planStatus: PlanStatusType }) =>
        ({ type: CHANGE_CHECKOUT_WITH_PLAN_STATUS, payload } as const),
    changePlanStatus: (payload: PlanStatusType) => ({ type: CHANGE_PLAN_STATUS, payload } as const),
    changePaywallDisplay: (payload: boolean) => ({ type: CHANGE_SUBSCRIPTION_WINDOW_DISPLAY, payload } as const),
    getSubscriptionPlans: (payload: Array<SubscriptionsPlan>) => ({ type: GET_SUBSCRIPTION_PLANS, payload } as const),
    setPlansForCompany: (payload: { plans: Array<CommonPlanType>; currPlan: CommonPlanType | null }) =>
        ({ type: SET_PLANS_FOR_COMPANY, payload } as const),
}

export const defaultCheckout = {
    url: null,
    type: 'pay' as const,
}

export const defaultPlanStatus = { planId: null, loader: false }

type BillingStateType = {
    checkout: CheckoutDataType
    isOpenPaywall: boolean
    subPlans: Array<SubscriptionsPlan>
    currentPlan: null | CommonPlanType
    plansForCompany: Array<CommonPlanType>
    planStatus: PlanStatusType
}

export const initialState: BillingStateType = {
    checkout: defaultCheckout,
    isOpenPaywall: false,
    subPlans: [],
    currentPlan: null,
    plansForCompany: [],
    planStatus: defaultPlanStatus,
}

type BillingActionTypes = InferActionsTypes<typeof billingActions>

export const billingReducer = (state: BillingStateType, action: BillingActionTypes): BillingStateType => {
    switch (action.type) {
        case CHANGE_CHECKOUT:
            return {
                ...state,
                checkout: action.payload,
                planStatus: action.payload.url === null ? defaultPlanStatus : state.planStatus,
            }
        case CHANGE_SUBSCRIPTION_WINDOW_DISPLAY:
            return { ...state, isOpenPaywall: action.payload }
        case GET_SUBSCRIPTION_PLANS:
            return { ...state, subPlans: action.payload }
        case SET_PLANS_FOR_COMPANY:
            return {
                ...state,
                plansForCompany: action.payload.plans,
                currentPlan: action.payload.currPlan,
                checkout: defaultCheckout,
                planStatus: defaultPlanStatus,
            }
        case CHANGE_PLAN_STATUS:
            return {
                ...state,
                planStatus: action.payload,
            }
        case CHANGE_CHECKOUT_WITH_PLAN_STATUS:
            return {
                ...state,
                checkout: action.payload.checkout,
                planStatus: action.payload.planStatus,
            }
        default:
            return state
    }
}
