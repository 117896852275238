import { DialogContentText, DialogActions } from '@material-ui/core'
import styled from 'styled-components'

import { AlertDescription } from 'styles/alert-styles'
import { ButtonOutlined, ButtonFilled } from 'styles/buttons-styles'

export const CancelAlertDescription = styled(AlertDescription)`
    &.MuiTypography-root {
        margin: 12px 0 4px;
    }
`

export const ExpiredAtWrapp = styled.strong`
    font-weight: 600;
`

export const CancelAdditionalDescription = styled(DialogContentText)`
    &.MuiTypography-root {
        color: rgba(51, 51, 51, 0.5);
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 24px;
    }
`

export const CancelAlertDialogActions = styled(DialogActions)`
    &.MuiDialogActions-root {
        padding: 0;
        display: flex;
        justify-content: space-between;
    }
`

const btnStyled = `
    height: 40px;
    width: 130px;
`

export const ConfirmAlertBtn = styled(ButtonFilled)`
    ${btnStyled}
`

export const CancelAlertBtn = styled(ButtonOutlined)`
    ${btnStyled}
`
