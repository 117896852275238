import React, { FC, CSSProperties } from 'react'

import { TooltipProps } from '@material-ui/core'

import { ReactComponent as ArrowTooltipSVG } from 'assets/arrow-tooltip.svg'

import { TooltipRoot, TooltipArrowWrapper, TooltipDescription } from './styles'

type TProps = {
    description?: string
    offset?: string
    contentStyles?: CSSProperties
} & TooltipProps

//todo: create own tooltip or find solution to perform MobileDayPicker.tsx tree
const TooltipContainer: FC<TProps> = ({
    children,
    title,
    description,
    offset = '',
    arrow = true,
    style,
    contentStyles,
    ...otherProps
}) => {
    const tooltip = (
        <div className="tooltip-content-wrapper" style={contentStyles}>
            <span>{title}</span>
            {description !== undefined && <TooltipDescription>{description}</TooltipDescription>}
            {arrow && (
                <TooltipArrowWrapper className="tooltip-arrow">
                    <ArrowTooltipSVG width={5} height={10} />
                </TooltipArrowWrapper>
            )}
        </div>
    )

    return (
        <TooltipRoot
            title={tooltip}
            PopperProps={{
                style,
                popperOptions: { modifiers: { offset: { offset } } },
                ...otherProps.PopperProps,
            }}
            {...otherProps}
        >
            {children}
        </TooltipRoot>
    )
}

TooltipContainer.displayName = 'TooltipContainer'

export default TooltipContainer
