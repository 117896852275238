import { EReservableType } from 'types/data.types'

import { SpaceScreenMapPinType } from '../types'

export const getIsAvatarSeat = (
    pinType: SpaceScreenMapPinType,
    reservableType: EReservableType,
    disableAvatar: boolean,
) => {
    switch (pinType) {
        case SpaceScreenMapPinType.ONE_BOOKED_PART:
        case SpaceScreenMapPinType.UNAVAILABLE_FREE:
            return true

        case SpaceScreenMapPinType.ONE_BOOKED_FULL: {
            return !(disableAvatar && reservableType === EReservableType.SEAT)
        }
        default:
            return false
    }
}

export const getIsMultiCounterSeat = (
    pinType: SpaceScreenMapPinType,
    reservableType: EReservableType,
    disableCounter: boolean,
) => {
    switch (pinType) {
        case SpaceScreenMapPinType.MULTI_BOOKED_PART:
        case SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
            return true

        case SpaceScreenMapPinType.MULTI_BOOKED_FULL: {
            if (disableCounter && reservableType === EReservableType.SEAT) {
                return false
            } else {
                return true
            }
        }
        default:
            return false
    }
}
