import React, { FC } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { API } from 'aws-amplify'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput } from 'components/atoms'
import { usePreloader } from 'context'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled, ButtonString } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

import { TitleWrapper, TitleAuth, InputRowWrapp } from './style'
import { BlueArrowRight } from '../../styles'
import { CHECK_EMAIL_SEARCH_TYPE } from '../../types'

interface IFormInputs {
    firstName: string
    lastName: string
    companyName: string
    phoneNumber: string
    email: string
}

const nonBusinessDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'msn.com',
    'live.com',
    'outlook.com',
    'icloud.com',
    'me.com',
    'mail.com',
    'inbox.com',
    'gmx.com',
    'fastmail.com',
    'zoho.com',
    'yandex.com',
    'protonmail.com',
    'email.com',
    'rocketmail.com',
    'bellsouth.net',
    'charter.net',
    'comcast.net',
    'cox.net',
    'earthlink.net',
    'juno.com',
    'btinternet.com',
    'virginmedia.com',
    'blueyonder.co.uk',
    'freeserve.co.uk',
    'live.co.uk',
]

const schema = yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    companyName: yup.string().required('Company name is required!'),
    email: yup
        .string()
        .required('Business email is required!')
        .test('is-business-email', 'Email must be a business email', (value) => {
            const domain = value.split('@')[1]
            return !nonBusinessDomains.includes(domain)
        }),
    phoneNumber: yup.string().required('Phone is required!'),
})
const ExploreDemo: FC = () => {
    const { t } = useTranslation(['translation'])
    const navigate = useNavigate()

    const { showPreloader } = usePreloader()
    const dispatch = useAppDispatch()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const handleCreateDemoAccount = async (dataSubmit: IFormInputs) => {
        try {
            showPreloader(true)

            await API.post(`demo-account`, '/common/demo-account/create', {
                body: {
                    firstname: dataSubmit.firstName,
                    lastname: dataSubmit.lastName,
                    companyName: dataSubmit.companyName,
                    phone: dataSubmit.phoneNumber,
                    email: dataSubmit.email,
                    source: 'web',
                },
            })

            analytics.logEvent(AnalyticsEvents.DEMO_ACCOUNT_CREATED)

            if (window.dataLayer) {
                window.dataLayer.push({ event: 'demo-account-created' })
            }

            navigate(AUTH_ROUTES.CHECK_EMAIL, {
                state: {
                    email: dataSubmit.email,
                    type: CHECK_EMAIL_SEARCH_TYPE.REGISTER,
                    backButton: {
                        url: AUTH_ROUTES.LOGIN,
                        text: t('Back to Log in'),
                    },
                },
            })

            localStorage.setItem('pathname', `/dashboard`)
        } catch (e) {
            dispatch(
                messageActions.messageShown({ text: t("Sorry, the demo account wasn't created!"), severity: 'error' }),
            )
        } finally {
            showPreloader(false)
        }
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleCreateDemoAccount)()
        }
    }

    return (
        <>
            <TitleWrapper>
                <TitleAuth>{t('Request for demo access')}</TitleAuth>
                <Link to={AUTH_ROUTES.LOGIN}>
                    <ButtonString className="login_small">
                        {t('Log in')}
                        <BlueArrowRight />
                    </ButtonString>
                </Link>
            </TitleWrapper>

            <InputRowWrapp>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('First name')}
                            helperText={errors.firstName?.message ? (t(errors.firstName?.message) as string) : ''}
                            error={!!errors.firstName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('Last name')}
                            helperText={errors.lastName?.message ? (t(errors.lastName?.message) as string) : ''}
                            error={!!errors.lastName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
            </InputRowWrapp>
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Business email')}
                        helperText={errors.email?.message ? (t(errors.email?.message) as string) : ''}
                        error={!!errors.email?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Company')}
                        helperText={errors.companyName?.message ? (t(errors.companyName?.message) as string) : ''}
                        error={!!errors.companyName?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Phone')}
                        helperText={errors.phoneNumber?.message ? (t(errors.phoneNumber?.message) as string) : ''}
                        error={!!errors.phoneNumber?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <ButtonFilled width="100%" onClick={handleSubmit(handleCreateDemoAccount)}>
                {t('Submit')}
            </ButtonFilled>
        </>
    )
}

export default ExploreDemo
