import { Dialog, DialogContentText, DialogTitle } from '@material-ui/core'
import styled from 'styled-components'

export const AlertRoot = styled(Dialog)`
    & .MuiDialog-paper {
        width: 360px;
        background-color: ${(props) => props.theme.color.greyBackground};
        padding: 24px;
        border-radius: 20px;
        color: ${(props) => props.theme.color.black};
    }
`

export const AlertTitle = styled(DialogTitle)`
    &.MuiDialogTitle-root {
        padding: 0;
    }

    .MuiTypography-root {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
`

export const AlertDescription = styled(DialogContentText)`
    &.MuiTypography-root {
        font-size: 14px;
        color: ${(props) => props.theme.color.black};
    }
`
