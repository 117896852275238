import { useTranslation } from 'react-i18next'

import { useResizeDevice } from 'context'

import * as S from './styles'

type BigSwitchProps<T> = {
    selected: T
    onSelect: (mode: T) => void
    options: { value: T; label: string; IconComponent?: JSX.Element }[]
}
const BigSwitch = <T extends string | number>({ options, selected, onSelect }: BigSwitchProps<T>) => {
    const { t } = useTranslation('translation')
    const { isMobile } = useResizeDevice()
    return (
        <S.BigSwitchWrap>
            {options.map(({ value, label, IconComponent }) => (
                <S.FilterSwitchButton
                    key={value}
                    selected={selected === value}
                    onClick={() => onSelect(value)}
                    isMobile={isMobile}
                >
                    {IconComponent} {t(label)}
                </S.FilterSwitchButton>
            ))}
        </S.BigSwitchWrap>
    )
}

export default BigSwitch
