export const getTimeFilterQueryString = (startTime: string, endTime: string) => {
    return {
        and: [
            {
                startBookingTime: { lt: endTime },
            },
            {
                endBookingTime: { gt: startTime },
            },
        ],
    }
}
