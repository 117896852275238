import React, { useCallback } from 'react'

import { IconMarkSVG, IconMarkSuccessSVG } from 'assets/icons'

import { Container, ListItem } from './styles'

interface HintRequirementsProps {
    value: string
    isValid: [boolean, boolean, boolean]
}

const HintRequirements = ({ value, isValid }: HintRequirementsProps) => {
    const renderItem = useCallback((text: string, successfully: boolean) => {
        const Icon = successfully ? IconMarkSuccessSVG : IconMarkSVG
        return (
            <ListItem successfully={successfully}>
                <>
                    <Icon />
                    {text}
                </>
            </ListItem>
        )
    }, [])

    return (
        <Container>
            <>
                {renderItem('At least 8 characters', isValid[0])}
                {renderItem('Upper case & Lower case characters & digit', isValid[1])}
                {renderItem('Minimum of one digit', isValid[2])}
            </>
        </Container>
    )
}

export default HintRequirements
