import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 154px;
    border-radius: 12px;
    padding: 8px 20px 10px 16px;
`

export const Title = styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
`
