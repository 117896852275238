import React, { FC } from 'react'

import { Box } from '@material-ui/core'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'

import { ProgressBarRoot, ProgressBarText } from './styles'

type Props = {
    value?: number | null
    size?: number
    Wrapper?: FC
}

const ProgressBar: FC<Props> = ({ value, size, Wrapper }) => {
    const CurrentWrapper = typeof Wrapper !== 'undefined' ? Wrapper : ProgressBarRoot

    return (
        <CurrentWrapper className="flex-center preloader">
            {value ? <CircularProgressWithLabel value={value} size={size} /> : <CircularProgress size={size} />}
        </CurrentWrapper>
    )
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <div className="position-center">
                <ProgressBarText>{`${Math.round(props.value)}%`}</ProgressBarText>
            </div>
        </Box>
    )
}

export default ProgressBar
