import React, { FC } from 'react'

import { getConvertedDate } from 'helpers'
import { AlertRoot, AlertTitle } from 'styles/alert-styles'

import * as S from './styles'

type Props = {
    isOpen: boolean
    expiresAt: string
    quantity: number
    onConfirm: () => void
    onCancel: () => void
}

const CancelAlert: FC<Props> = ({ isOpen, expiresAt, quantity, onConfirm, onCancel }) => {
    return (
        <AlertRoot
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
        >
            <AlertTitle id="alert-dialog-title">Cancel subscription</AlertTitle>
            <S.CancelAlertDescription>
                Your subscription will end{' '}
                <S.ExpiredAtWrapp>{getConvertedDate(expiresAt, 'MMMM D, YYYY')}</S.ExpiredAtWrapp>
            </S.CancelAlertDescription>
            <S.CancelAdditionalDescription>
                You have {quantity} users, they will lose booking functionality.
            </S.CancelAdditionalDescription>
            <S.CancelAlertDialogActions>
                <S.ConfirmAlertBtn onClick={onConfirm}>Confirm</S.ConfirmAlertBtn>
                <S.CancelAlertBtn onClick={onCancel}>Cancel</S.CancelAlertBtn>
            </S.CancelAlertDialogActions>
        </AlertRoot>
    )
}

export default CancelAlert
