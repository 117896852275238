import React, { CSSProperties, FC, useCallback } from 'react'

import { TextFieldProps, TextField } from '@material-ui/core'

import { Root, InputContainer, ExternalLabel } from './styles'

type Props = {
    minHeight?: number
    maxWidth?: number
    dynamicWidth?: boolean
    fieldType?: 'input' | 'textarea'
    helperText?: string
    background?: string
    withOutMargin?: boolean
} & TextFieldProps

const inputProps = {
    disableUnderline: true,
}

const CustomInputV2: FC<Props> = ({
    error,
    minHeight,
    maxWidth,
    style,
    fieldType = 'input',
    dynamicWidth = false,
    helperText,
    background,
    variant = 'standard',
    InputProps,
    withOutMargin,
    className,
    onChange,
    type,
    ...props
}) => {
    const handleChange = useCallback(
        (event) => {
            if (type === 'number') {
                onChange?.(event.target.valueAsNumber)
                return
            }

            onChange?.(event.target.value)
        },
        [onChange],
    )

    return (
        <Root withOutMargin={withOutMargin} className={className}>
            {props?.label && <ExternalLabel>{props?.label}</ExternalLabel>}
            <InputContainer
                className="custom_input_root"
                maxWidth={maxWidth}
                minHeight={minHeight ? minHeight : 56}
                dynamicWidth={dynamicWidth}
                hasError={error}
                type={fieldType}
                background={background}
                withOutMargin={withOutMargin}
            >
                <TextField
                    {...props}
                    type={type}
                    error={error}
                    variant={variant as any}
                    InputProps={{ ...inputProps, ...InputProps }}
                    helperText={error ? helperText : undefined}
                    label={undefined}
                    onChange={handleChange}
                />
            </InputContainer>
        </Root>
    )
}

export type { Props as CustomInputV2Props }
export default CustomInputV2
