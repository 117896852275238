import { FC } from 'react'

import { Switch, SwitchProps } from '@material-ui/core'

import { CustomSwitchRoot, CustomMediumSwitchRoot } from './styles'

type TProps = {
    label?: string
} & SwitchProps

const CustomSwitch: FC<TProps> = ({ label, id, size, name, checked, ...props }) => {
    const Content = (
        <>
            <Switch id={id} name={name} checked={checked} {...props} />
            {label !== undefined && <label htmlFor={id}>{label}</label>}
        </>
    )

    if (size === 'medium') {
        return <CustomMediumSwitchRoot disabled={props.disabled}>{Content}</CustomMediumSwitchRoot>
    }

    return <CustomSwitchRoot disabled={props.disabled}>{Content}</CustomSwitchRoot>
}

export default CustomSwitch
