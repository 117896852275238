import { FC } from 'react'

import { Text } from 'react-konva'

import { ColorAvatarSeat } from '../types'

type Props = {
    colors: ColorAvatarSeat
    amount: string
    selected: boolean
    size: {
        width: number
        height: number
        radius: number
    }
    offsetX?: number
    offsetY?: number
}

const TextBookingsAmount: FC<Props> = ({ amount, selected, size: { width, radius }, colors, offsetX, offsetY = 3 }) => {
    const colorText = selected ? colors.colorSelectedText : colors.colorText

    return (
        <Text
            width={width}
            x={-width / 2}
            y={-radius / 2 + 2}
            align="center"
            text={amount}
            fontFamily="Poppins"
            fontSize={14}
            fontStyle="bold"
            fill={colorText}
            offsetX={offsetX}
            offsetY={offsetY}
        />
    )
}

export default TextBookingsAmount
