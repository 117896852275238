import React, { FC, ReactNode, isValidElement } from 'react'

import { CloseBtn } from 'components/atoms'
import { noop } from 'helpers'
import { AlertRoot } from 'styles/alert-styles'

import * as S from './styles'

type Props = {
    title?: string
    icon?: ReactNode
    isWidget?: boolean
    buttons?: ReactNode
    closeButton?: boolean
    buttonsDirection?: 'column' | 'row'
    onClose?: () => void
    children?: React.ReactNode
}

const CustomAlert: FC<Props> = ({
    title,
    children,
    isWidget = true,
    buttons,
    closeButton = false,
    onClose = noop,
    icon,
    buttonsDirection = 'column',
}) => {
    const hasIcon = isValidElement(icon)

    const body = (
        <S.AlertContentWrapper hasIcon={hasIcon}>
            {hasIcon && icon}
            {title && <S.AlertTitleWrapp id="alert-dialog-title">{title}</S.AlertTitleWrapp>}
            {children && <S.AlertDescriptionWrapp id="alert-dialog-description">{children}</S.AlertDescriptionWrapp>}
            {isValidElement(buttons) && (
                <S.AlertDialogActions direction={buttonsDirection}>{buttons}</S.AlertDialogActions>
            )}
            {closeButton && (
                <S.CloseButtonWrapp>
                    <CloseBtn onClose={onClose} />
                </S.CloseButtonWrapp>
            )}
        </S.AlertContentWrapper>
    )

    return isWidget ? (
        <AlertRoot
            open
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableScrollLock
            disableEnforceFocus
        >
            {body}
        </AlertRoot>
    ) : (
        <S.AlertInfoWrapp aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {body}
        </S.AlertInfoWrapp>
    )
}

export default CustomAlert
