import React, { FC } from 'react'

import { useAppSelector } from 'hooks/redux'

import Avatar from '../Avatar'

type Props = {
    imgSize?: number
    fontSize?: number
}

const UserAvatar: FC<Props> = ({ imgSize, fontSize }) => {
    const fullName = useAppSelector((rootState) => rootState.user.fullName)
    const src = useAppSelector((rootState) => rootState.user.photo)

    return <Avatar src={src} name={fullName} imgSize={imgSize} fontSize={fontSize} />
}

export default UserAvatar
