import React, { FC } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { API } from 'aws-amplify'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { CustomInput } from 'components/atoms'
import { InputRowWrapp } from 'containers/Auth/styles'
import { usePreloader } from 'context'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled } from 'styles/buttons-styles'

interface IFormInputs {
    companyName: string
    firstName: string
    lastName: string
    phoneNumber: string
}

const schema = yup.object().shape({
    companyName: yup.string().required('Company name is required!'),
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    phoneNumber: yup.string(),
})

type ContactFormProps = {
    email: string
    onSuccessfulSubmit: () => void
}
export const ContactForm: FC<ContactFormProps> = ({ email, onSuccessfulSubmit }) => {
    const { t } = useTranslation('translation')
    const { showPreloader } = usePreloader()
    const dispatch = useAppDispatch()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const handleCreateDemoAccount = async (dataSubmit: IFormInputs) => {
        try {
            showPreloader(true)

            await API.post(`demo-account`, '/common/demo-account/create', {
                body: {
                    firstname: dataSubmit.firstName,
                    lastname: dataSubmit.lastName,
                    companyName: dataSubmit.companyName,
                    phone: dataSubmit.phoneNumber,
                    email: email,
                    source: 'ms-teams',
                },
            })

            analytics.logEvent(AnalyticsEvents.DEMO_ACCOUNT_CREATED)

            onSuccessfulSubmit()
        } catch (e) {
            dispatch(
                messageActions.messageShown({ text: t("Sorry, the demo account wasn't created!"), severity: 'error' }),
            )
        }
        showPreloader(false)
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleCreateDemoAccount)()
        }
    }

    return (
        <>
            <InputRowWrapp>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('First name')}
                            helperText={errors.firstName?.message}
                            error={!!errors.firstName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('Last name')}
                            helperText={errors.lastName?.message}
                            error={!!errors.lastName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
            </InputRowWrapp>
            <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Company')}
                        helperText={errors.companyName?.message}
                        error={!!errors.companyName?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Phone')}
                        helperText={errors.phoneNumber?.message}
                        error={!!errors.phoneNumber?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <ButtonFilled width="100%" onClick={handleSubmit(handleCreateDemoAccount)}>
                {t('Submit')}
            </ButtonFilled>
        </>
    )
}
