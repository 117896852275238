import { FC } from 'react'

import { Tooltip, TooltipProps } from '@material-ui/core'
import styled from 'styled-components'

export const TooltipRoot = styled((props) => (
    <Tooltip
        classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
        children={props.children}
        {...props}
    />
))`
    & .tooltip {
        border-radius: 6px;
        padding: 0;
        margin: 0;
        background-color: transparent;

        svg {
            fill: #333333;
        }
    }

    & .tooltip-content-wrapper {
        background-color: #333333;
        border-radius: 6px;
        padding: 3px 6px;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    & .arrow {
        display: none;
    }

    &[x-placement='left'] {
        .tooltip-arrow {
            top: 50%;
            transform: translate(0, -50%);
            right: -4px;
        }
    }

    &[x-placement='right'] {
        .tooltip-arrow {
            top: 50%;
            transform: translate(0, -50%) rotate(180deg);
            left: -4px;
        }
    }

    &[x-placement='top'] {
        .tooltip-arrow {
            bottom: -6.5px;
            transform: rotate(90deg) translate(0, 2.5px);
            left: 50%;
        }
    }

    &[x-placement='bottom'] {
        .tooltip-arrow {
            top: -6.5px;
            transform: rotate(-90deg) translate(0, -2.5px);
            left: 50%;
        }
    }
` as FC<TooltipProps>

export const TooltipDescription = styled.p`
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    margin: 0;
`

export const TooltipArrowWrapper = styled.div`
    display: flex;
    position: absolute;
`
