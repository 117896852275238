import React, { FC, createContext, useContext, useState, ReactElement } from 'react'

import styled from 'styled-components'

import { TickSVG } from 'assets/common'
import CustomAlert from 'components/molecules/CustomAlert'
import { noop } from 'helpers'
import { ButtonOutlined } from 'styles/buttons-styles'

export type AlertDataType = {
    title: string
    hasIcon?: boolean
    children?: ReactElement | null
    btnType?: string
}

export const defaultAlert: AlertDataType = {
    title: '',
    hasIcon: false,
    children: null,
}

type AlertContextType = {
    changeAlertData: (data: AlertDataType) => void
}

const AlertCTX = createContext<AlertContextType>({ changeAlertData: noop })

const AlertIconWrapper = styled.div`
    background-color: #1bd665;
    width: 40px;
    height: 40px;
    border-radius: 100%;
`

export const AlertContext: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [alertData, setAlertData] = useState(defaultAlert)

    const changeAlertData = (data: AlertDataType) => setAlertData(data)

    const closeHandler = () => setAlertData(defaultAlert)

    return (
        <AlertCTX.Provider value={{ changeAlertData }}>
            {children}
            {alertData.title && (
                <CustomAlert
                    title={alertData.title}
                    icon={
                        alertData.hasIcon ? (
                            <AlertIconWrapper className="flex-center">
                                <TickSVG width={16} height={13} fill="#ffffff" />
                            </AlertIconWrapper>
                        ) : undefined
                    }
                    onClose={closeHandler}
                    buttons={<ButtonOutlined onClick={closeHandler}>{alertData.btnType || 'OK'}</ButtonOutlined>}
                >
                    {alertData.children}
                </CustomAlert>
            )}
        </AlertCTX.Provider>
    )
}

export const useCustomAlert = (): AlertContextType => useContext(AlertCTX)
