import React, { FC } from 'react'

import CompanyConfirmation from 'containers/Auth/components/CompanyConfirmation'
import { AlertContext } from 'context/alertContext'
import { useAuth } from 'context/auth'
import { BillingProvider } from 'context/billing'
import { CompanyProvider } from 'context/company'

import Wrapper from '../components/Wrapper/Wrapper'

const ManagerTemplate: FC = () => {
    const { userAttributes } = useAuth()

    let content = <Wrapper />

    if (!userAttributes?.isCompanyCreated) {
        content = <CompanyConfirmation />
    }

    return (
        <CompanyProvider>
            <AlertContext>
                <BillingProvider>{content}</BillingProvider>
            </AlertContext>
        </CompanyProvider>
    )
}

export default ManagerTemplate
