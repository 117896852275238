import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ENotificationChannel, ERoleEmployee } from 'types/data.types'
import { EUserLocale, EUserShareAttendance, User, UserResponse } from 'types/user.types'

const initialState: User = {
    id: '',
    favouriteOfficeID: '',
    favouriteReservableID: '',
    firstname: '',
    lastname: '',
    fullName: '',
    cognitoUserId: '',
    email: '',
    photo: null,
    role: ERoleEmployee.EMPLOYEE,
    departmentIDs: [],
    favouriteColleagueIDs: [],
    buddyID: '',
    // departments: [],
    position: null,
    office: null,
    demoAccountID: null,
    notificationChannel: ENotificationChannel.EMAIL,
    locale: EUserLocale.EN,
    shareAttendance: EUserShareAttendance.ALL,
    isAdmin: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userDataReceived: (state, action: PayloadAction<UserResponse>) => {
            const {
                // departments,
                departmentIDs,
                favouriteColleagueIDs,
                buddyID,
                Office,
                Position,
                notificationChannels,
                firstname,
                lastname,
                locale,
                shareAttendance,
                role,
                demoAccountID,
                favouriteReservableID,
                ...other
            } = action.payload
            return {
                ...other,
                firstname,
                lastname,
                locale: locale || EUserLocale.EN,
                shareAttendance: shareAttendance || EUserShareAttendance.ALL,
                departmentIDs: departmentIDs || [],
                favouriteColleagueIDs: favouriteColleagueIDs || [],
                favouriteReservableID: favouriteReservableID,
                buddyID: buddyID,
                // departments: departments || [],
                position: Position,
                office: Office,
                demoAccountID: demoAccountID,
                notificationChannel:
                    notificationChannels === null ? state.notificationChannel : notificationChannels[0],
                fullName: `${firstname} ${lastname}`,
                role,
                isAdmin: role === ERoleEmployee.MANAGER || role === ERoleEmployee.OWNER,
            }
        },
        userDataCleared: () => initialState,
    },
})

export const userActions = userSlice.actions
export default userSlice.reducer
