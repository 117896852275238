import styled, { css } from 'styled-components'

import BGImage from 'images/login-bg.png'

export const LoaderWrapper = styled.div`
    width: 100%;
    top: 0;
    left: 0;
`

export const PageContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url(${BGImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LoginFormContainer = styled.div`
    width: 420px;
    background: white;
    box-shadow: 0px 20px 32px -16px rgba(49, 49, 57, 0.15);
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
`

export const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    width: 100%;
    margin-bottom: 32px;
`
export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    //width: 100%;
    height: 28px;
    //padding: 32px 0 64px;

    @media (min-width: 480px) {
        //height: 28px;
    }

    @media (min-width: 1024px) {
        //height: auto;
    }
`

export const Logo = styled.img`
    //width: 72px;

    @media (min-width: 480px) {
        //width: 112px;
    }

    @media (min-width: 1024px) {
        //width: 200px;
    }
`

export const LanguageSwitcherContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const WelcomeText = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.color.black};
    margin-bottom: 16px;
`

export const SuccessText = styled.div`
    font-size: 32px;
    font-weight: 600;
`

export const CentralRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const MainText = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.color.black};
`

export const Text = styled.div<{ isCentered?: boolean }>`
    font-size: 16px;
    color: ${(props) => props.theme.color.black80};

    ${(props) =>
        props.isCentered &&
        css`
            text-align: center;
        `}
`

export const SecondaryText = styled.div<{ isCentered?: boolean }>`
    font-size: 16px;
    color: ${(props) => props.theme.color.secondaryBlack};

    ${(props) =>
        props.isCentered &&
        css`
            text-align: center;
        `}
`

export const SecondarySmallText = styled(SecondaryText)`
    font-size: 12px;
`

export const IconContainer = styled.div`
    margin-right: 6px;
    display: flex;
    color: white;

    svg {
        width: 24px;
        height: 24px;
        margin-right: 0;
    }
`
