import { useFlags } from 'launchdarkly-react-client-sdk'
import useImage from 'use-image'

import { EReservableType } from 'types/data.types'

import * as PinImagesSvg from '../../../../assets/pins'
import {
    getParkingSpaceScreenImages,
    getRoomSpaceScreenImages,
    getSeatSpaceScreenImages,
    getSeatTowelSpaceScreenImages,
} from '../helpers/getImages'
import { getIsAvatarSeat, getIsMultiCounterSeat } from '../helpers/hasContent'
import { SpaceScreenSeatPin } from '../types'

export const useSpaceScreenPinsImages = (
    reservableType: EReservableType,
    typeSeat: SpaceScreenSeatPin,
    selected: boolean,
    noSelectable: boolean,
) => {
    const { towelPin } = useFlags()

    const { pinType, isOnlyBlockedTime } = typeSeat
    let imageSrc = ''

    switch (reservableType) {
        case EReservableType.SEAT: {
            if (towelPin && !isOnlyBlockedTime) {
                imageSrc = getSeatTowelSpaceScreenImages(pinType, selected)
            } else {
                imageSrc = getSeatSpaceScreenImages(pinType, selected)
            }
            break
        }
        case EReservableType.PARKING:
            imageSrc = getParkingSpaceScreenImages(pinType, selected)
            break
        case EReservableType.ROOM:
            imageSrc = getRoomSpaceScreenImages(pinType, selected)
            break

        default:
            break
    }
    if (noSelectable) {
        switch (reservableType) {
            case EReservableType.SEAT: {
                imageSrc = PinImagesSvg.DefaultFreeUnavailableSeat
                break
            }
            case EReservableType.PARKING:
                imageSrc = getParkingSpaceScreenImages(pinType, selected)
                break
            case EReservableType.ROOM:
                imageSrc = PinImagesSvg.DefaultBookedFullSpaceScreenRoom
                break
        }
    }

    const hasAvatar = getIsAvatarSeat(pinType, reservableType, !!towelPin)
    const hasMultiCounter = getIsMultiCounterSeat(pinType, reservableType, !!towelPin)

    const [imagePin] = useImage(imageSrc)
    return { imagePin, hasAvatar, hasMultiCounter }
}
