import { gql } from '@apollo/client'

export const SPACE = gql`
    fragment SpaceFields on Space {
        id
        floorPlanFilePath
        name
        address
        workingHoursFrom
        workingHoursTo
        officeID
        companyID
    }
`

export const RESERVABLE = gql`
    fragment ReservableFields on Reservable {
        id
        x
        y
        availabilityType
        type
        employeeID
        departmentID
        spaceID
        companyID
        name
        maxAmountOfPeople
        zonePoints
        color
        ReservableToEmployees {
            items {
                id
                employeeID
                employee {
                    id
                    firstname
                    lastname
                    fullName
                    photo
                }
            }
        }
        ReservableToDepartments {
            items {
                id
                departmentID
                Department {
                    id
                    name
                }
            }
        }
    }
`

export const EMPLOYEE = gql`
    fragment EmployeeFields on Employee {
        id
        email
        firstname
        lastname
        fullName
        photo
        officeID
        positionID
        departmentIDs
        departmentsString
        defaultPlan
        status
        companyID
        notificationChannels
        createdAt
        role
        locale
        shareAttendance
        favouriteColleagueIDs
        buddyID
        favouriteReservableID
        birthday
    }
`

export const DEPARTMENT = gql`
    fragment DepartmentFields on Department {
        id
        name
        companyID
    }
`

export const POSITION = gql`
    fragment PositionFields on Position {
        id
        name
        companyID
    }
`

export const OFFICE = gql`
    fragment OfficeFields on Office {
        id
        name
        companyID
    }
`

export const BOOKING = gql`
    fragment BookingFields on Booking {
        id
        startTime
        endTime
        reservableID
        status
        employeeID
        companyID
        isTeamEvent
        checkInStatus
        startBookingTime
        endBookingTime
    }
`

export const PERSONIO = gql`
    fragment PersonioFields on PersonioIntegration {
        companyID
        clientID
        clientSecret
        status
        error
        nextSyncAt
        lastSyncedAt
        hasInvitationEnabled
        hasSyncBookingsToPersonioEnabled
    }
`

export const BAMBOOHR = gql`
    fragment BambooHRFields on BambooHRIntegration {
        companyId
        hasInvitationEnabled
        syncStatus
        lastSyncAt
        companyDomain
    }
`

export const SLACK = gql`
    fragment SlackFields on SlackIntegration {
        companyID
        sendDayScheduleAt
        enableCheckInFeature
        enableSendingDaySchedule
    }
`

export const SUBSCRIPTION_PLAN = gql`
    fragment SubscriptionPlanFields on BillingPlan {
        id
        name
        billingType
        billingPeriod
        initialPrice {
            currency
            amount
        }
        recurringPrice {
            currency
            amount
        }
    }
`

export const SUBSCRIBED_BILLING = gql`
    fragment SubscribedBillingFields on SubscribedBilling {
        quantity
        updateUrl
        cancelUrl
        subscribedAt
        subscriptionId
        discount {
            type
            value
        }
    }
`

export const TRIAL_BILLING = gql`
    fragment TrialBillingFields on TrialBilling {
        expiresAt
    }
`

export const SUBSCRIPTION_FOR_COMPANY = gql`
    fragment SubscriptionForCompanyFields on SubscribedBilling {
        companyId
        status
        subscriptionPlanId
        expiresAt
    }
`

export const RULES = gql`
    fragment RulesFields on Rules {
        id
        enableMinDaysInOffice
        minDaysInOffice
        minDaysInOfficeTimeUnit
        maxDaysInOffice
        homeIsDefault
        turnOnCheckIn
        autoAssignWaitingList
        horizonOfPlanningTimeUnit
        horizonOfPlanningTimeAmount
        denyRecurringBookingOverThePlanningHorizon
        startTimeOfNotifications
        checkInWaitingTime
        checkInCancellingTime
        turnOnAttendanceNotifications
        dayOfAttendanceNotifications
        hourOfAttendanceNotifications
        updateEmployeeDataFromSource
        turnOffCheckInForPersonalSeats
        isPersonalSeatAvailableToday
        isTeamSeatAvailableToday
        turnOnMonthlyReport
        emailForMonthlyReport
        workingDays
        blockReservationOnNonWorkingDays
        disableProfileEditing
        defaultShareAttendance
        turnOnDeletingData
        deleteDataAfter
    }
`

export const CALENDAR_DATA = gql`
    fragment CalendarDataFields on Employee {
        id
        BookingsByWeek(
            limit: 1000
            yearIsoWeekNumber: { beginsWith: { year: $year, isoWeekNumber: $weekNumber } }
            filter: { isBlocked: { ne: true } }
        ) {
            items {
                id
                startTime
                endTime
                isTeamEvent
                isFullDay
                isBlocked
                BookingRequest {
                    repeatType
                }
                reservable {
                    id
                    type
                    Space {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const CALENDAR_EVENT = gql`
    fragment CalendarEventFields on CalendarEvents {
        id
        title
        reservableID
        description
        employeesIDs
        startTime
        endTime
        canCurrentUserEdit
        spaceID
        companyID
        isOnlineMeeting
        onlineMeetingUrl
        isPrivate
    }
`

export const MAIN_EMPLOYEE = gql`
    fragment MainEmployeeFields on Employee {
        id
        photo
        firstname
        lastname
        fullName
        departmentIDs
        departmentsString
    }
`
