import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: false,
    reducers: {
        loaderShow: (_, action: PayloadAction<boolean>) => action.payload,
    },
})

export const loaderActions = loaderSlice.actions
export default loaderSlice.reducer
