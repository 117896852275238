import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { useAppDispatch, useAppSelector } from 'hooks'
import { messageActions } from 'store/slices/message'

const Message = () => {
    const messageState = useAppSelector((state) => state.message)
    const dispatch = useAppDispatch()

    const closeHandler = () => dispatch(messageActions.messageClosed())

    if (messageState.text === '') {
        return null
    }

    return (
        <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={messageState.severity === 'error' ? null : 2500}
            onClose={closeHandler}
        >
            <MuiAlert elevation={6} variant="filled" onClose={closeHandler} severity={messageState.severity}>
                {messageState.text}
            </MuiAlert>
        </Snackbar>
    )
}

export default Message
