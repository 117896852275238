import styled from 'styled-components'

export const AdvantagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
`

export const AdvantagesTitle = styled.h1`
    font-size: 6vh;
    font-weight: 600;
    line-height: 7vh;
    margin-top: 105px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(51, 51, 51, 1);
`

export const AdvantagesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const AdvantagesListItem = styled.span`
    display: flex;
    font-size: 2.5vh;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(51, 51, 51, 0.8);
    gap: 10px;

    & img {
        width: 22px;
        height: 22px;
    }
`
