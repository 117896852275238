import { ReservableEmployeesType } from 'types/data.types'

import { getInitials } from '../../../../helpers'

export const getInitialsEmployees = (employees: Array<ReservableEmployeesType>) => {
    if (employees.length === 0) {
        return null
    }
    if (employees.length === 1) {
        return getInitials(employees?.[0]?.employee?.firstname + ' ' + employees?.[0]?.employee?.lastname)
    } else {
        return employees.length + ''
    }
}
