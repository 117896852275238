import useImage from 'use-image'

import { EAvailabilityType, EReservableType } from 'types/data.types'

import { getRoomEditorImages, getSeatEditorImages, getParkingEditorImages } from '../helpers/getImages'

export const usePinsImages = (
    reservableType: EReservableType,
    availabilityType: EAvailabilityType,
    selected: boolean,
) => {
    let imageSrc = ''

    switch (reservableType) {
        case EReservableType.SEAT:
            imageSrc = getSeatEditorImages(availabilityType, selected)
            break

        case EReservableType.ROOM:
            imageSrc = getRoomEditorImages(availabilityType, selected)
            break

        case EReservableType.PARKING:
            imageSrc = getParkingEditorImages(availabilityType, selected)
            break

        default:
            break
    }

    const [img] = useImage(imageSrc)
    return img
}
