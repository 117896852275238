import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import styled from 'styled-components'

export const ArrowWrapp = styled.div`
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 6px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

export type PositionType = 'left' | 'right'

export const Arrow = styled(ArrowForwardIosIcon)<{ position: PositionType }>`
    &.MuiSvgIcon-root {
        height: 12px;
        color: rgba(51, 51, 51, 0.5);
        ${({ position }) => (position === 'left' ? `transform: rotate(180deg);` : ``)};
    }
`
