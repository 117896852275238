import styled from 'styled-components'

export const CapterraContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const CapterraStarsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 130px;
`

export const CapterraItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    width: 100%;
    height: 47px;
    border-right: 1px solid rgba(51, 51, 51, 0.25);

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
        border-right: none;
    }
`

export const CapterraText = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    color: #333333cc;
    white-space: nowrap;
`
