import React, { FC, MouseEventHandler } from 'react'

import { ArrowWrapp, Arrow, PositionType } from './styles'

type Props = {
    position: PositionType
    onClick: MouseEventHandler<HTMLDivElement>
}

const ArrowBtn: FC<Props> = ({ position, onClick }) => {
    return (
        <ArrowWrapp onClick={onClick} className={`arrow-${position}-btn`}>
            <Arrow position={position} />
        </ArrowWrapp>
    )
}

export default ArrowBtn
