import { FC } from 'react'

import { SceneContext } from 'konva/lib/Context'
import { Image, Group } from 'react-konva'
import useImage from 'use-image'

import { getInitials } from 'helpers'
import { useGetImagesS3 } from 'hooks'
import { MainEmployeeData } from 'types/data.types'

import TextReservable from './TextReservable'
import { calcClipFuncAvatar } from '../helpers/calcClipFuncAvatar'
import { ColorAvatarSeat } from '../types'

type Props = {
    employee: MainEmployeeData | null
    size: {
        width: number
        height: number
        radius: number
        avatar: number
    }
    selected: boolean
    colors: ColorAvatarSeat
    offsetX?: number
    offsetY?: number
    activeAvatar?: boolean
    text?: string | null
}

const AvatarReservable: FC<Props> = ({
    employee,
    size,
    offsetX,
    offsetY,
    selected,
    colors,
    activeAvatar = true,
    text,
}) => {
    const [photoSrc] = useGetImagesS3(employee?.photo)
    const [photoImage] = useImage(photoSrc || '')

    const { width, height, radius, avatar } = size

    if (photoImage) {
        const isHorizontalImage = photoImage.naturalWidth > photoImage.naturalHeight
        const heightCoefficient = !isHorizontalImage ? photoImage.naturalHeight / photoImage.naturalWidth : 1
        const widthCoefficient = isHorizontalImage ? photoImage.naturalWidth / photoImage.naturalHeight : 1

        photoImage.width = width * widthCoefficient
        photoImage.height = height * heightCoefficient
    }

    const initialText = getInitials(employee?.firstname + ' ' + employee?.lastname)

    return (
        <>
            {photoImage && activeAvatar ? (
                <Group
                    offsetX={offsetX}
                    offsetY={offsetY}
                    clipFunc={(ctx: SceneContext) => calcClipFuncAvatar(ctx, photoImage.width / 2, height / 2, avatar)}
                    x={-photoImage.width / 2}
                    y={-radius}
                >
                    <Image image={photoImage} />
                </Group>
            ) : (
                <Group offsetX={offsetX} offsetY={offsetY}>
                    <TextReservable
                        size={size}
                        text={text || initialText}
                        selected={selected}
                        colors={colors}
                        offsetY={size.avatar - size.radius + 3}
                    />
                </Group>
            )}
        </>
    )
}

export default AvatarReservable
