import React, { FC, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { IconRocketSVG } from 'assets/icons'
import LogInScreenLink from 'components/LogInScreenLink'
import { CustomInput } from 'components/atoms'
import { TitleAuthSignIn, LoginORField, ForgotPasswordBtn } from 'containers/Auth/styles'
import { usePreloader, useAuth } from 'context'
import { getQueryParams, getErrorMessage } from 'helpers'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled, LinkOutlined } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

import { DemoBlock, DemoBlockText } from './styles'

const schema = yup.object().shape({
    email: yup.string().required('Email is required!').email('Email is invalid!'),
    password: yup.string().required('Password is required!'),
})

type SignInFormInputs = {
    email: string
    password: string
}

const SignIn: FC = () => {
    const { t } = useTranslation('translation')
    const { signIn } = useAuth()
    const { showPreloader } = usePreloader()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const {
        control,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm<SignInFormInputs>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const handleSignIn = async (dataSubmit: SignInFormInputs) => {
        await signIn(dataSubmit.email, dataSubmit.password)
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit(handleSignIn)()
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const params = getQueryParams(location.search, ['code', 'username'])

            if (Object.keys(params).length === 0) {
                return
            }

            showPreloader(true)
            try {
                await Auth.confirmSignUp(params.username, params.code)
                dispatch(
                    messageActions.messageShown({
                        text: t('Your email is confirmed. Please log in.'),
                        severity: 'success',
                    }),
                )
            } catch (err) {
                dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
            }
            navigate(AUTH_ROUTES.LOGIN)
            showPreloader(false)
        }

        fetchData()
    }, [location])

    return (
        <>
            <TitleAuthSignIn>{t('Log in')}</TitleAuthSignIn>
            {/*<S.TitleWrapper>*/}
            {/*    <S.TitleAuth className="margin_left_content margin_bottom_0">Log in</S.TitleAuth>*/}
            {/*    <Link to={AUTH_ROUTES.SIGNUP}>*/}
            {/*        <ButtonString>Register as company</ButtonString>*/}
            {/*    </Link>*/}
            {/*</S.TitleWrapper>*/}

            <form>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="email"
                            label={t('Email')}
                            helperText={errors.email?.message ? (t(errors.email.message) as string) : undefined}
                            error={!!errors.email?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="password"
                            label={t('Password')}
                            helperText={errors.password?.message ? (t(errors.password.message) as string) : undefined}
                            error={!!errors.password?.message}
                            onKeyPress={handleKeyPressed}
                            autoComplete="off"
                        />
                    )}
                ></Controller>

                <Link to={AUTH_ROUTES.PASSWORD_RECOVERY}>
                    <ForgotPasswordBtn className="margin_left_content login_small">
                        {t('Forgot Password')}
                    </ForgotPasswordBtn>
                </Link>

                <ButtonFilled width="100%" type="button" onClick={handleSubmit(handleSignIn)}>
                    {t('Log in')}
                </ButtonFilled>
                <LoginORField>{t('or')}</LoginORField>
                {/*<LoginThroughServices />*/}
                <Link to={AUTH_ROUTES.LOGIN_SSO}>
                    <LinkOutlined height="48px" as="div">
                        {t('Sign in with SSO')}
                    </LinkOutlined>
                </Link>
            </form>
            <DemoBlock>
                <DemoBlockText>{t('New to Yoffix?')}</DemoBlockText>
                <Link to={AUTH_ROUTES.EXPLORE_DEMO}>
                    <LogInScreenLink size={14}>
                        {t('Explore demo account')}
                        <IconRocketSVG />
                    </LogInScreenLink>
                </Link>
            </DemoBlock>
        </>
    )
}

export default SignIn
