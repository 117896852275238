import styled from 'styled-components'

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
    gap: 16px;
`

export const TitleAuth = styled.h1`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    margin: 32px 0 0 0;
    color: rgba(51, 51, 51, 1);
`

export const InputRowWrapp = styled.div`
    display: flex;

    & > div:not(:last-child) {
        margin-right: 8px;
    }

    & > div {
        width: 50%;
    }
`
