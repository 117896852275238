import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { DatePaginationBtnsType } from 'types/common.types'

import { HeaderInterfaceWrapper, HeaderInterfaceTodayBtn } from './styles'
import { WeekInterface } from '../index'

type Props = {
    disabledTodayBtn: boolean
    handleChangeDate: (type?: DatePaginationBtnsType) => void
}

const HeaderInterface: FC<Props> = ({ disabledTodayBtn, handleChangeDate }) => {
    const { t } = useTranslation('translation')
    return (
        <HeaderInterfaceWrapper>
            <HeaderInterfaceTodayBtn
                disabled={disabledTodayBtn}
                onClick={disabledTodayBtn ? undefined : () => handleChangeDate()}
            >
                {t('Today')}
            </HeaderInterfaceTodayBtn>
            <WeekInterface onChangeDate={handleChangeDate} />
        </HeaderInterfaceWrapper>
    )
}

export default HeaderInterface
