import styled, { css } from 'styled-components'

export const TooltipProps = {
    placement: 'right' as const,
    offset: '0, 28px',
}

export const SidebarRoot = styled.aside`
    background-color: ${(props) => props.theme.color.black};
    padding: 24px 20px 9px 20px;
    border-radius: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1300;
    width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 8px 8px;
`

export const MobileSidebarRoot = styled.aside`
    background-color: ${(props) => props.theme.color.black};
    padding-inline: 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

export const LogoWrapper = styled.div`
    flex: 1;
    display: flex;
`

export const SidebarList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    display: flex;
    align-items: center;

    &.main-navigation {
        flex: 2;
    }

    &.bottom-navigation {
        flex: 1;
        justify-content: flex-end;
    }
`

export const SidebarListItem = styled.li<{ isActive?: boolean }>`
    width: 32px;
    height: 32px;
    margin: 9px 0;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.15);

        svg {
            fill-opacity: ${(props) => (props.isActive ? 1 : 0.5)};
        }
    }

    ${(props) =>
        props.isActive &&
        css`
            background: rgba(255, 255, 255, 0.15);

            svg {
                fill-opacity: 1;
            }
        `}
`

export const SidebarSeparator = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    width: 16px;
    margin: 12px 0 13px 0;
`

export const ProfileAvatarWrap = styled(SidebarListItem)`
    width: 46px;
    height: 46px;
    border: 4px solid #3f3f44;
    border-radius: 50%;
`
