import React, { FC, useEffect, useState } from 'react'

import { getInitials } from 'helpers'

import { AvatarRoot } from './styles'
import { useGetImagesS3 } from '../../../hooks'

type Props = {
    name: string
    src?: string | null
    imgSize?: number
    fontSize?: number
}

const Avatar: FC<Props> = ({ name, src = '', imgSize = 48, fontSize }) => {
    const [cachedSrc, setCachedSrc] = useState<string | null>(null)
    const [pathImg] = useGetImagesS3(src)

    useEffect(() => {
        const fetchImage = async () => {
            if (src) {
                const cachedImage = sessionStorage.getItem(src)
                if (cachedImage) {
                    setCachedSrc(cachedImage)
                } else if (pathImg) {
                    setCachedSrc(pathImg)
                    sessionStorage.setItem(src, pathImg)
                }
            }
        }

        fetchImage()
    }, [src, pathImg])

    let imgSrc = cachedSrc
    if (name === 'Anonymous User') {
        imgSrc = '/images/anonymous-user.svg'
    }

    return (
        <AvatarRoot
            fontSize={fontSize || imgSize / 2}
            imgSize={imgSize}
            src={imgSrc || undefined}
            alt={`${name} avatar`}
            aria-label={!imgSrc ? `${name} avatar` : undefined}
        >
            {getInitials(name)}
        </AvatarRoot>
    )
}

export default Avatar
