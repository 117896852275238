import { ERulesHorizon } from 'types/data.types'

const MONTH_COUNT = 31
const WEEK_COUNT = 7

export type HorizonUnitTypes = 'Days' | 'Weeks' | 'Months'

export const getHorizonDay = (time: number, horizonUnit: ERulesHorizon) => {
    switch (horizonUnit) {
        case ERulesHorizon.DAY:
            return time
        case ERulesHorizon.WEEK:
            return time * WEEK_COUNT
        case ERulesHorizon.MONTH:
            return time * MONTH_COUNT
        default:
            return 0
    }
}

export const getValueFromKey = (key: HorizonUnitTypes) => {
    switch (key) {
        case 'Days':
            return ERulesHorizon.DAY
        case 'Weeks':
            return ERulesHorizon.WEEK
        case 'Months':
            return ERulesHorizon.MONTH
        default:
            return ERulesHorizon.DAY
    }
}

export const getKeyFromValue = (value: ERulesHorizon): HorizonUnitTypes => {
    switch (value) {
        case ERulesHorizon.DAY:
            return 'Days'
        case ERulesHorizon.WEEK:
            return 'Weeks'
        case ERulesHorizon.MONTH:
            return 'Months'
        default:
            return 'Days'
    }
}
