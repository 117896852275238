import { CircularProgress } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { ButtonFilled, ButtonOutlined } from 'styles/buttons-styles'
import { FeatureStatusType } from 'types/billing.types'

export const BillingCardContainer = styled.div`
    background-color: ${(props) => props.theme.color.greyBackground};
    border-radius: 20px;
    padding: 16px 32px 24px 32px;
    display: flex;
    flex-direction: column;
    color: #333333;
    min-width: 318px;
    margin-right: 24px;
`

export const BillingCardTitleWrapp = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`

export const BillingCardTitle = styled.h3`
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    margin: 0 16px 0 0;
`

export const BillingCardPriceWrapp = styled.div`
    display: flex;
    align-items: center;
`

export const BillingCardPrice = styled.h2`
    color: #333333;
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
    margin: 0;
`

export const BillingCardDivider = styled.span`
    background-color: rgba(51, 51, 51, 0.5);
    height: 36px;
    width: 1px;
    transform: rotate(15deg);
    margin: 0 14px;
`

export const BillingCardPeriod = styled.div`
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
    font-weight: 400;
`

export const BollingCardFeatureWrapper = styled.div<{ insidePaywall?: boolean }>`
    @media (max-height: 720px) {
        display: flex;
        flex-direction: column;
    }
`

export const BillingCardFeaturesBlock = styled.ul`
    margin: 16px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
`

export const BillingCardFeature = styled.li<{ type: FeatureStatusType }>`
    display: flex;
    font-size: 14px;
    line-height: 22.4px;
    margin-bottom: 8px;
    align-items: center;
    color: ${(props) => (props.type === 'close' ? 'rgba(51, 51, 51, 0.5)' : 'inherit')};

    svg {
        margin-right: 12px;
    }

    &:last-child {
        margin-bottom: 14px;
    }
`

export const BillingCardTagWrapp = styled.div`
    margin-left: 8px;
`

const btnProps = `
    height: 40px;
    width: 100%;
    margin-top: 18px; 
`

export const ButtonLoader = styled(CircularProgress)`
    color: ${(props) => props.theme.color.white};
    margin-right: 8px;
`

export const ButtonFilledWrapp = styled(ButtonFilled)<{ isCancel?: boolean }>`
    ${btnProps}
    ${(props) =>
        props.isCancel &&
        css`
            border: none;
            color: ${props.theme.color.coral};
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        `}
`

export const ButtonOutlinedWrapp = styled(ButtonOutlined)`
    ${btnProps}
`
