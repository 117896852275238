import moment from 'moment/moment'

import { getHorizonDay, getKeyFromValue } from 'helpers'
import { EmployeeStatus, TRules } from 'types/data.types'

export const LimitByDepartment = 15

export const statusesList = [
    {
        id: EmployeeStatus.REGISTRED,
        name: 'Registered',
    },
    {
        id: EmployeeStatus.UNREGISTRED,
        name: 'Unregistered',
    },
]

export const getHorizonOfBooking = (date: string, isManager: boolean, rules?: TRules): string | undefined => {
    if (rules === undefined || isManager) {
        return undefined
    }

    if (
        moment(date).diff(moment(), 'days') >=
        getHorizonDay(rules.horizonOfPlanningTimeAmount, rules.horizonOfPlanningTimeUnit)
    ) {
        return `${rules.horizonOfPlanningTimeAmount} ${getKeyFromValue(rules.horizonOfPlanningTimeUnit).toLowerCase()}`
    }
}

export const getColorByIsPast = (color: string, isPastDay: boolean) => {
    return isPastDay ? `${color}80` : color
}
