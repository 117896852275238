import * as PinImagesSvg from 'assets/pins'
import { EAvailabilityType } from 'types/data.types'

import { SpaceScreenMapPinType } from '../types'

export const getSeatEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeSeatImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamSeatImage(selected)

        case EAvailabilityType.BOOKED_FOR_PERSON:
            return getPersonSeatImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableSeatImage(selected)

        default:
            return getFreeSeatImage(selected)
    }
}

export const getSeatSpaceScreenImages = (type: SpaceScreenMapPinType, selected: boolean) => {
    switch (type) {
        case SpaceScreenMapPinType.FREE_FULL:
            return getFreeSeatImage(selected)

        case SpaceScreenMapPinType.UNAVAILABLE_FREE:
            return getFreeUnavailableSeatImage(selected)

        case SpaceScreenMapPinType.ONE_BOOKED_PART:
            return getOnePartBookedFreeSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.MULTI_BOOKED_PART:
            return getMultiBookedFreeSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
            return getMultiBookedUnavailableSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.ONE_BOOKED_FULL:
            return getOneBookedFullSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.REPEAT_FULL:
            return getRepeatedFullSpaceScreenSeatImage()

        case SpaceScreenMapPinType.MULTI_BOOKED_FULL:
            return getMultiBookedFullSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.BLOCKED:
            return getBlockedSpaceScreenSeatImage()

        default:
            return getFreeSeatImage(selected)
    }
}

export const getParkingSpaceScreenImages = (type: SpaceScreenMapPinType, selected: boolean) => {
    switch (type) {
        case SpaceScreenMapPinType.ONE_BOOKED_FULL:
            return getOneBookedFullSpaceScreenParkingImage(selected)

        case SpaceScreenMapPinType.MULTI_BOOKED_FULL:
            return getMultiBookedFullSpaceScreenParkingImage(selected)

        case SpaceScreenMapPinType.MULTI_BOOKED_PART:
        case SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
            return getMultiBookedFreeParkingImage(selected)

        case SpaceScreenMapPinType.BLOCKED:
            return getBlockedSpaceScreenSeatImage()

        case SpaceScreenMapPinType.UNAVAILABLE_FREE:
            return getNotAvaliableParkingImage(selected)

        default:
            return getFreeParkingImage(selected)
    }
}

export const getSeatTowelSpaceScreenImages = (type: SpaceScreenMapPinType, selected: boolean) => {
    switch (type) {
        case SpaceScreenMapPinType.FREE_FULL:
            return getFreeSeatImage(selected)

        case SpaceScreenMapPinType.UNAVAILABLE_FREE:
            return getFreeUnavailableSeatImage(selected)

        case SpaceScreenMapPinType.ONE_BOOKED_PART:
            return getOnePartBookedFreeSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.MULTI_BOOKED_PART:
            return getMultiBookedFreeSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
            return getMultiBookedUnavailableSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.ONE_BOOKED_FULL:
            return getTowelBookedFullSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.MULTI_BOOKED_FULL:
            return getTowelBookedFullSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.REPEAT_FULL:
            return getTowelBookedFullSpaceScreenSeatImage(selected)

        case SpaceScreenMapPinType.BLOCKED:
            return getBlockedSpaceScreenSeatImage()

        default:
            return getFreeSeatImage(selected)
    }
}

export const getRoomSpaceScreenImages = (type: SpaceScreenMapPinType, selected: boolean) => {
    switch (type) {
        case SpaceScreenMapPinType.FREE_FULL:
            return getFreeSpaceScreenRoomImage(selected)

        case SpaceScreenMapPinType.ONE_BOOKED_FULL:
            return getFullBookedSpaceScreenRoomImage(selected)

        default:
            return getFreeSpaceScreenRoomImage(selected)
    }
}

export const getRoomEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeRoomImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamRoomImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableRoomImage(selected)

        default:
            return getFreeRoomImage(selected)
    }
}

export const getParkingEditorImages = (type: EAvailabilityType, selected: boolean) => {
    switch (type) {
        case EAvailabilityType.FREE_FOR_ALL:
            return getFreeParkingImage(selected)

        case EAvailabilityType.BOOKED_FOR_TEAM:
            return getTeamParkingImage(selected)

        case EAvailabilityType.BOOKED_FOR_PERSON:
            return getPersonParkingImage(selected)

        case EAvailabilityType.UNAVALIABLE:
            return getNotAvaliableParkingImage(selected)

        default:
            return getFreeParkingImage(selected)
    }
}

const getFreeSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeSeat : PinImagesSvg.DefaultFreeSeat
}

const getFreeUnavailableSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeSeat : PinImagesSvg.DefaultFreeUnavailableSeat
}

const getFreeRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeRoom : PinImagesSvg.DefaultFreeRoom
}

const getOneBookedFullSpaceScreenParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedBookedFullParking : PinImagesSvg.DefaultBookedFullParking
}

const getMultiBookedFullSpaceScreenParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedMultiBookedFullParking : PinImagesSvg.DefaultMultiBookedFullParking
}

const getFreeParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeParking : PinImagesSvg.DefaultFreeParking
}

const getMultiBookedFreeParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedMultiBookedFreeParking : PinImagesSvg.DefaultMultiBookedFreeParking
}

const getTeamSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamSeat : PinImagesSvg.DefaultTeamSeat
}

const getTeamRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamRoom : PinImagesSvg.DefaultTeamRoom
}

const getTeamParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedTeamParking : PinImagesSvg.DefaultTeamParking
}

const getPersonSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedPersonSeat : PinImagesSvg.DefaultSeetEmployee
}

const getPersonParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedPersonParking : PinImagesSvg.DefaultPersonParking
}

const getNotAvaliableSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableSeat : PinImagesSvg.DefaultNotAvaliableSeat
}

const getNotAvaliableRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableRoom : PinImagesSvg.DefaultNotAvaliableRoom
}

const getNotAvaliableParkingImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedNotAvaliableParking : PinImagesSvg.DefaultNotAvaliableParking
}
const getMultiBookedFreeSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedMultiBookedFreeSeat : PinImagesSvg.DefaultMultiBookedFreeSeat
}

const getMultiBookedUnavailableSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedMultiBookedFreeSeat : PinImagesSvg.DefaultMultiBookedUnavailableSeat
}

const getOnePartBookedFreeSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedBookedOnePartSeat : PinImagesSvg.DefaultBookedOnePartSeat
}

const getBlockedSpaceScreenSeatImage = () => {
    return PinImagesSvg.DefaultBlockedBookingSeat
}
const getOneBookedFullSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedBookedFullSeat : PinImagesSvg.DefaultBookedFullSeat
}

const getRepeatedFullSpaceScreenSeatImage = () => {
    return PinImagesSvg.DefaultBookedRepeatSeat
}
const getMultiBookedFullSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedMultiBookedFullSeat : PinImagesSvg.DefaultMultiBookedFullSeat
}

const getTowelBookedFullSpaceScreenSeatImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedBookedTowelSeat : PinImagesSvg.DefaultBookedTowelSeat
}

const getFreeSpaceScreenRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeSpaceScreenRoom : PinImagesSvg.DefaultFreeSpaceScreenRoom
}

const getFullBookedSpaceScreenRoomImage = (selected: boolean) => {
    return selected ? PinImagesSvg.SelectedFreeSpaceScreenRoom : PinImagesSvg.DefaultBookedFullSpaceScreenRoom
}
