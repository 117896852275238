import styled from 'styled-components'

export const Quote = styled.span`
    margin-bottom: auto;
    font-size: 2.5vh;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(51, 51, 51, 0.8);
`

export const Backlight = styled.span`
    background-image: url();
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    padding: 4px;
`

export const TechTimeDash = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: right;
    margin-left: 18px;
    margin-right: 4px;
`

export const TechTimeLink = styled.a`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: right;
    text-decoration: underline;

    &,
    &:visited,
    &:link {
        color: rgba(49, 49, 57, 0.5);
    }
`
