import { COLORS } from './constants'
import { SpaceScreenMapPinType } from '../types'

export const getColorTextAmount = (type: SpaceScreenMapPinType, isBlocked: boolean) => {
    switch (true) {
        case type === SpaceScreenMapPinType.MULTI_BOOKED_PART && !isBlocked:
        case type === SpaceScreenMapPinType.ONE_BOOKED_PART && isBlocked:
        case type === SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
        case type === SpaceScreenMapPinType.UNAVAILABLE_FREE:
            return COLORS.WHITE

        case type === SpaceScreenMapPinType.MULTI_BOOKED_FULL && !isBlocked:
        case type === SpaceScreenMapPinType.ONE_BOOKED_FULL && isBlocked:
        default:
            return COLORS.BLACK
    }
}

export const getColorCircleBlocked = (type: SpaceScreenMapPinType, selected: boolean, isBlocked: boolean) => {
    switch (true) {
        case type === SpaceScreenMapPinType.UNAVAILABLE_FREE && isBlocked && !selected:
        case type === SpaceScreenMapPinType.ONE_BOOKED_PART && isBlocked && !selected:
            return COLORS.BLACK

        case type === SpaceScreenMapPinType.ONE_BOOKED_FULL && isBlocked:
        case type === SpaceScreenMapPinType.UNAVAILABLE_FREE && isBlocked && selected:
        case type === SpaceScreenMapPinType.ONE_BOOKED_PART && isBlocked && selected:
            return COLORS.WHITE

        default:
            return COLORS.WHITE
    }
}
