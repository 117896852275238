import React, { FC } from 'react'

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import { AuthRouteWrapp } from 'components/molecules'
import { AUTH_ROUTES } from 'types/routes'

import CheckEmailSingUp from './components/CheckEmailSingUp'
import ExploreDemo from './components/ExploreDemo'
import LoginSSO from './components/LoginSSO'
import PasswordConfirmation from './components/PasswordConfirmation'
import PasswordRecovery from './components/PasswordRecovery'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import MSTeamsLoginPage from '../MSTeamsLoginPage'
import MicrosoftLandingPage from '../MicrosoftLandingPage'

const Auth: FC = () => {
    const { pathname, search } = useLocation()

    return (
        <Routes>
            <Route element={<AuthRouteWrapp />}>
                <Route path={AUTH_ROUTES.LOGIN} element={<SignIn />} />
                <Route path={AUTH_ROUTES.LOGIN_SSO} element={<LoginSSO />} />
                <Route path={AUTH_ROUTES.CONFIRM_COMPANY} element={<SignIn />} />
                <Route path={AUTH_ROUTES.SIGNUP} element={<SignUp />} />
                <Route path={AUTH_ROUTES.CHECK_EMAIL} element={<CheckEmailSingUp />} />
                <Route path={AUTH_ROUTES.PASSWORD_RECOVERY} element={<PasswordRecovery />} />
                <Route path={AUTH_ROUTES.CONFIRM_EMPLOYEE} element={<PasswordConfirmation type="CONFIRM" />} />
                <Route path={AUTH_ROUTES.FORGOT_PASSWORD} element={<PasswordConfirmation type="FORGOT" />} />
                <Route path={AUTH_ROUTES.EXPLORE_DEMO} element={<ExploreDemo />} />
                <Route
                    path="*"
                    element={<Navigate to={{ pathname: AUTH_ROUTES.LOGIN }} state={{ pathname, search }} />}
                />
            </Route>
            <Route path={AUTH_ROUTES.MS_LANDING_PAGE} element={<MicrosoftLandingPage />} />
            <Route path={AUTH_ROUTES.MS_LOGIN_PAGE} element={<MSTeamsLoginPage />} />
        </Routes>
    )
}
export default Auth
