import { CalendarSVG, SpacesSVG, EmployeesSVG } from 'assets/common'
import { DashboardSVG, RoomsSVG, SpaceScreenSVG } from 'assets/navigation'

import { SidebarLinks } from './types'

export const sidebarLinks: SidebarLinks = {
    dashboard: {
        page: 'Dashboard',
        icon: <DashboardSVG />,
    },
    spaces: {
        page: 'Spaces',
        icon: <SpaceScreenSVG />,
    },
    calendar: {
        page: 'Calendar',
        icon: <CalendarSVG />,
    },
    rooms: {
        page: 'Rooms',
        icon: <RoomsSVG />,
    },
    offices: {
        page: 'Offices',
        icon: <SpacesSVG />,
    },
    employees: {
        page: 'Employees',
        icon: <EmployeesSVG fill="#ffffff" fillOpacity={0.3} />,
    },
}

export const employeeSidebarLinks: SidebarLinks = {
    ...sidebarLinks,
    dashboard: null,
    employees: null,
    offices: null,
}

export const teamLeadSidebarLinks: SidebarLinks = {
    ...sidebarLinks,
    offices: null,
}
