import styled from 'styled-components'

export const ProgressBarRoot = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    z-index: 2000;
`

export const ProgressBarText = styled.span`
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
`
