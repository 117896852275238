import moment, { Moment } from 'moment'

import getAWSFormatDate from './getAWSFormatDate'

const getWeeksByMoment = (day: Date | Moment, startMonday = true, amountDays = 7) => {
    const weekStart = startMonday ? moment(day).clone().startOf('isoWeek') : moment(day).utc()

    return new Array(amountDays).fill(null).map((_, index) => getAWSFormatDate(moment(weekStart).add(index, 'days')))
}

export const getWeeksByMomentByDay = (startDay = 1, amountDays = 7) => {
    const weekStart = moment().clone().isoWeekday(startDay)

    return new Array(amountDays).fill(null).map((_, index) => getAWSFormatDate(moment(weekStart).add(index, 'days')))
}

export default getWeeksByMoment
