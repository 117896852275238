import { useEffect, useRef } from 'react'

function createRootElement(id: string) {
    const rootContainer = document.createElement('div')
    rootContainer.setAttribute('id', id)
    return rootContainer
}

type PortalElem = HTMLDivElement | null

export const usePortal = (isCreate: boolean): PortalElem => {
    const rootElemRef = useRef<PortalElem>(null)

    useEffect(() => {
        if (rootElemRef.current !== null && isCreate) {
            const existingParent = document.querySelector(`#subscription-window`)
            const parentElem = existingParent || createRootElement('subscription-window')

            if (!existingParent) {
                document.body.appendChild(parentElem)
            }

            parentElem.appendChild(rootElemRef.current)

            return () => {
                document.body.removeChild(parentElem)
            }
        }
    }, [isCreate])

    if (!rootElemRef.current && isCreate) {
        rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
}
