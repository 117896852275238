import React from 'react'

import { Quote, TechTimeLink, TechTimeDash, Backlight } from './style'

const TechTimeQuote = () => (
    <Quote>
        “Yoffix is <Backlight>#1</Backlight> hybrid office management software“
        <TechTimeDash>—</TechTimeDash>
        <TechTimeLink
            href="https://www.techtimes.com/articles/282289/20221026/top-5-best-hybrid-office-management-software-2022.html"
            target="_blank"
        >
            TechTimes
        </TechTimeLink>
    </Quote>
)

export default TechTimeQuote
