import { useEffect } from 'react'

const usePaddle = () => {
    useEffect(() => {
        const Paddle = window.Paddle
        if (Paddle !== undefined) {
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                Paddle.Environment.set('sandbox')
            }

            Paddle.Setup({ vendor: +process.env.REACT_APP_PADDLE_VENDOR_ID })
        }
    }, [])
}

export default usePaddle
