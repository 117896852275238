import React from 'react'

import { IconDropdown } from 'assets/icons'

import { DropDownIconContainer } from './style'

type DropdownArrowIconProps = {
    onClick?: () => void
}

const DropdownArrowIconSmall = ({ onClick }: DropdownArrowIconProps) => (
    <DropDownIconContainer onClick={onClick}>
        <IconDropdown />
    </DropDownIconContainer>
)

export default DropdownArrowIconSmall
