import { useQuery } from '@apollo/client'

import { QUERY_GET_S3_LINK } from 'graphql/queries'
import { ES3OperationType, ImageResizeInput } from 'types/data.types'

const useGetImagesS3 = (src: string | undefined | null, resize?: ImageResizeInput): [string | null, boolean] => {
    const { data, loading } = useQuery<
        { getS3SignedLink: string },
        { operationType: ES3OperationType; path: string; resize?: ImageResizeInput }
    >(QUERY_GET_S3_LINK, {
        skip: !src,
        fetchPolicy: 'cache-first',
        variables: {
            operationType: ES3OperationType.GET_S3_OBJECT,
            path: 'private/' + src,
            resize,
        },
    })

    const pathImg = data?.getS3SignedLink || null

    return [pathImg, loading]
}

export default useGetImagesS3
