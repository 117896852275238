export enum ROOM_SIZES {
    PADDING_ROOM_X = 10,
    WIDTH_IMAGE_BLOCK = 50,
    OFFSET_HOUR_CIRCLE = 2,
    RADIUS_HOUR_CIRCLE = 4,
    HEIGHT_ROOM = 40,
    HEIGHT_PICKER_ROOM = 80,
    MAX_LENGTH_NAME = 30,
}

export enum PIN_SIZES {
    HEIGHT_SEAT_PIN = 46,
    HEIGHT_PARKING_PIN = 65,
    WIDTH_PIN = 46,
    RADIUS_TEXT = 22.5,
}

export enum RESERVABLE_COLORS {
    TEXT = '#333333',
    TEXT_WHITE = '#FFFFFF',
    SELECTED = '#0019FF',
}

export enum COLORS {
    BLACK = '#333333',
    WHITE = '#FFFFFF',
    BLUE = '#0019FF',
}
