import { getInitials } from 'helpers'
import { ReservableDepartmentsType } from 'types/data.types'

export const getInitialsDepartment = (departments: Array<ReservableDepartmentsType>) => {
    if (departments.length === 0) {
        return ''
    }
    if (departments.length > 1) {
        return departments.length + ''
    } else {
        return getInitials(departments[0].Department?.name || null)
    }
}
