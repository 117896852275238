import React, { FC, useState, useEffect, useMemo } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { WarningSVG, CrossSVG } from 'assets/common'
import { IconPhoneSVG } from 'assets/icons'
import { useBilling, useResizeDevice } from 'context'
import { TARIFF } from 'types/billing.types'
import { TCompany } from 'types/data.types'

import * as S from './styles'

interface TrialExpiredProps {
    company: TCompany
}

const TrialExpired: FC<TrialExpiredProps> = ({ company }) => {
    const { t } = useTranslation('translation')
    const { isMobile } = useResizeDevice()
    const { currentPlan } = useBilling()

    const timeSessionStorage = sessionStorage.getItem('trialExpiredDismissed')

    const [showBlock, setShowBlock] = useState(false)

    const isDismissed = useMemo(() => {
        return timeSessionStorage ? moment().isBefore(moment(timeSessionStorage)) : false
    }, [timeSessionStorage])

    const daysRemaining = useMemo(() => {
        if (company.trialExpiresAt) {
            return Math.round((new Date(company.trialExpiresAt).getTime() - new Date().getTime()) / 60000 / 60 / 24)
        }
    }, [company?.trialExpiresAt])

    useEffect(() => {
        if (
            !isDismissed &&
            daysRemaining &&
            daysRemaining > 0 &&
            daysRemaining < 31 &&
            [TARIFF.TRIAL].includes(company?.tariff as TARIFF)
        ) {
            setShowBlock(true)
        }
        if ([TARIFF.TRIAL_EXPIRED].includes(company?.tariff as TARIFF)) {
            setShowBlock(true)
        }
    }, [company?.tariff, daysRemaining])

    const shouldDisplayCloseButton = useMemo(() => {
        return company?.tariff === TARIFF.TRIAL
    }, [company?.tariff])

    if (isMobile || !showBlock) {
        return null
    }

    const close = () => {
        const expiryDate = moment().add(1, 'day').toISOString()
        sessionStorage.setItem('trialExpiredDismissed', expiryDate)
        setShowBlock(false)
    }

    return (
        <S.TrialExpiredContainer className={'df align-items-center'}>
            <WarningSVG width={32} height={32} />
            <S.TrialExpiredTitle className={'ml-4'}>
                {company?.tariff === TARIFF.TRIAL_EXPIRED ? (
                    t('Your trial has expired')
                ) : (
                    <>
                        {t('Your trial expires in')} <b>{daysRemaining}</b> {t('days')}
                    </>
                )}
            </S.TrialExpiredTitle>

            <S.TrialExpiredButton href="https://yoffix.com/book-a-demo" target={'_blank'} className={'ml-8'}>
                <IconPhoneSVG className={'phone-svg'} /> {t('Book demo call')}
            </S.TrialExpiredButton>

            {shouldDisplayCloseButton && (
                <S.TrialExpiredButtonClose onClick={close} className={'ml-8'}>
                    <CrossSVG width={16} height={16} className={'close-svg'} />
                </S.TrialExpiredButtonClose>
            )}
        </S.TrialExpiredContainer>
    )
}

export default TrialExpired
