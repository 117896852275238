import styled from 'styled-components'

export default styled.span<{ size: number }>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: ${(props) => props.size}px;
    line-height: 21px;
    color: ${(props) => props.theme.color.blueButton};

    &:hover {
        text-decoration: underline;
    }
`
