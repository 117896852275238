import styled from 'styled-components'

export const Footer = styled.footer`
    margin-top: auto;
    display: flex;
    flex-direction: column;
`

export const FooterSignature = styled.span`
    font-size: 12px;
    color: #33333380;
    text-align: center;
    margin-top: 24px;
`

export const FooterLinks = styled.div`
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
`

export const FooterAmpersand = styled.span`
    font-size: 12px;
    color: #33333380;
`
