import { FC } from 'react'

import { Arc, Circle, Group } from 'react-konva'

import { PIN_CIRCLE } from 'types/data.types'

import { ROOM_SIZES } from '../helpers/constants'

type Props = {
    offsetX: number
    offsetY: number
    previewHours?: Array<PIN_CIRCLE>
    color?: string
    sizeCircle?: { radius: number; offset: number }
}

const TimeLineBooking: FC<Props> = ({
    offsetX,
    offsetY,
    previewHours,
    color,
    sizeCircle = { radius: ROOM_SIZES.RADIUS_HOUR_CIRCLE, offset: ROOM_SIZES.OFFSET_HOUR_CIRCLE },
}) => {
    return (
        <Group x={offsetX} y={offsetY}>
            {previewHours?.map((item, index) => (
                <HourBookCircle
                    key={index}
                    x={index * (sizeCircle.radius * 2 + sizeCircle.offset) + sizeCircle.radius}
                    color={color}
                    radiusCircle={sizeCircle.radius}
                    hour={{
                        firstHalf: item === PIN_CIRCLE.FULL || item === PIN_CIRCLE.FIRST_HALF,

                        secondHalf: item === PIN_CIRCLE.FULL || item === PIN_CIRCLE.SECOND_HALF,
                    }}
                />
            ))}
        </Group>
    )
}

type TypeBookHour = {
    firstHalf: boolean
    secondHalf: boolean
}

const HourBookCircle: FC<{ radiusCircle: number; hour: TypeBookHour; x: number; color?: string }> = ({
    hour,
    x,
    color = '#333333',
    radiusCircle,
}) => {
    return (
        <Group x={x}>
            <Circle radius={radiusCircle} stroke={color} strokeWidth={1} />
            {hour.firstHalf && (
                <Arc rotation={90} innerRadius={0} outerRadius={radiusCircle} angle={180} fill={color} />
            )}
            {hour.secondHalf && (
                <Arc x={-0.5} rotation={-90} innerRadius={0} outerRadius={radiusCircle} angle={180} fill={color} />
            )}
        </Group>
    )
}

export default TimeLineBooking
