import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { getAWSFormatDate, getWeeksByMoment } from 'helpers'
import { BookingResponse } from 'types/data.types'

import { checkCurrentEmployee, LimitByDepartment } from './helpers'
import * as T from './types'

export const initialCurrentDate = {
    currDay: getAWSFormatDate(new Date()),
    startWeekDate: getAWSFormatDate(moment().startOf('isoWeek')),
    weekNumber: moment().isoWeek(),
    weekDays: getWeeksByMoment(new Date()),
}

const initialState: T.CalendarSlice = {
    currentWeekDate: initialCurrentDate,
    headData: null,
    bodyData: [],
    departments: null,
    filteredName: '',
    page: 1,
    total: 0,
    hasMore: false,
    nextTokenByDepartment: null,
    isLoading: false,
    teamEventDate: null,
}

//todo: подгон - бек
let currentData = [] as T.CalendarSlice['bodyData']

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        dataFetching: (state) => {
            state.isLoading = true
        },
        calendarDataInstalled: (state, action: PayloadAction<T.CalendarFirstDataActionPayload>) => {
            const { ...rest } = action.payload

            return {
                ...state,
                ...rest,
            }
        },
        newBodyInstalled: (state, action: PayloadAction<T.CalendarDataBodyActionPayload>) => {
            const { nextTokenByDepartment, total, bodyData, ...rest } = action.payload

            let data = bodyData
            if (nextTokenByDepartment === undefined) {
                currentData = bodyData
                data = currentData.slice(0, LimitByDepartment * state.page)
            }

            return {
                ...state,
                isLoading: false,
                page: 1,
                bodyData: data,
                hasMore: Boolean(nextTokenByDepartment) && total >= LimitByDepartment,
                nextTokenByDepartment: nextTokenByDepartment === undefined ? null : nextTokenByDepartment,
                total,
                ...rest,
            }
        },
        newBookingAdded: (state, action: PayloadAction<BookingResponse>) => {
            return { ...state }
        },
        bookingDeleted: (state, action: PayloadAction<{ bookingId: string; employeeID: string }>) => {
            const { bookingId, employeeID } = action.payload

            if (state.headData === null) {
                return
            }

            if (checkCurrentEmployee(state.headData.id, employeeID)) {
                state.headData.BookingsByWeek.items = state.headData.BookingsByWeek.items.filter(
                    (book) => book.id !== bookingId,
                )
                return
            }

            const currentRowIndex = state.bodyData.findIndex((row) => row.id === action.payload.employeeID)
            if (currentRowIndex === -1) {
                return
            }
            state.bodyData[currentRowIndex].BookingsByWeek.items = state.bodyData[
                currentRowIndex
            ].BookingsByWeek.items.filter((book) => book.id !== bookingId)
        },
        newBodyDataByDepartmentAdded: (
            state,
            action: PayloadAction<Pick<T.CalendarSlice, 'bodyData' | 'nextTokenByDepartment' | 'total'>>,
        ) => {
            const bodyData = state.bodyData.concat(action.payload.bodyData)

            state.isLoading = false
            state.page = 1
            state.bodyData = bodyData
            state.nextTokenByDepartment = action.payload.nextTokenByDepartment
            state.hasMore = !!action.payload.nextTokenByDepartment && action.payload.total >= LimitByDepartment
            state.total = action.payload.total
        },
        newBodyDataFromCache: (state) => {
            const page = state.page + 1
            const data = state.bodyData.concat(
                currentData.slice(LimitByDepartment * (page - 1), LimitByDepartment * page),
            )

            state.page = page
            state.bodyData = data
            state.hasMore = data.length < currentData.length
        },
        teamEventDateAdded: (state, action: PayloadAction<string | null>) => {
            state.teamEventDate = action.payload
        },
        clearStore: () => {
            currentData = []
            return initialState
        },
        clearBodyData: (state) => {
            state.bodyData = []
        },
    },
})

export const calendarActions = calendarSlice.actions
export default calendarSlice.reducer
