import styled from 'styled-components'

export const CloseBtnRoot = styled.div`
    cursor: pointer;
    svg {
        fill: ${(props) => props.theme.color.black};
        fill-opacity: 0.5;
    }

    &:hover svg {
        fill-opacity: 1;
    }
`
