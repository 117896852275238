import React, { FC } from 'react'

import { DatePaginationBtnsType } from 'types/common.types'

import { HeaderWeekArrows } from './styles'
import ArrowBtn from '../ArrowBtn'

type Props = {
    onChangeDate: (dateMarker: DatePaginationBtnsType) => void
}

const WeekInterface: FC<Props> = ({ onChangeDate }) => {
    return (
        <HeaderWeekArrows>
            <ArrowBtn position="left" onClick={() => onChangeDate('prev')} />
            <ArrowBtn position="right" onClick={() => onChangeDate('next')} />
        </HeaderWeekArrows>
    )
}

export default WeekInterface
