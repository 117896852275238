import { FC, useEffect, useRef, useState } from 'react'

import Konva from 'konva'
import { Group, Rect, Path, Image, Text } from 'react-konva'

import { noop, checkExistsFunction } from 'helpers/utils'
import { TReservable } from 'types/data.types'

import { ROOM_SIZES } from '../helpers/constants'
import { usePinsImages } from '../hooks/usePinsImages'
import { SelectedReservable } from '../types'

type TProps = {
    reservable: TReservable
    selected?: boolean
    disabled?: boolean
    onDragStart?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onDragEnd?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onSelect: (val: SelectedReservable) => void
    onDragMove?: (e: Konva.KonvaEventObject<DragEvent>) => void
    onHover: () => void
    onOutHover: () => void
}

const pathData =
    'M6.16872 4.00308L5.39995 2.84992L5.39994 2.84991C4.7053 1.80795 4.35798 1.28697 3.8991 0.909816C3.49286 0.575922 3.02477 0.325407 2.52161 0.172602C1.95325 -1.18429e-06 1.32711 -1.23903e-06 0.0748243 -1.34851e-06L14.9252 -5.02526e-08C13.6729 -1.59731e-07 13.0468 -2.1447e-07 12.4784 0.172603C11.9752 0.325407 11.5071 0.575923 11.1009 0.909817C10.642 1.28697 10.2947 1.80796 9.60005 2.84992L8.83128 4.00308C8.38616 4.67077 8.16359 5.00461 7.88307 5.12094C7.63782 5.22265 7.36218 5.22265 7.11693 5.12094C6.83641 5.00461 6.61385 4.67077 6.16873 4.00309L6.16872 4.00308Z'

export const RoomEditorReservable: FC<TProps> = ({
    reservable,
    selected,
    onDragEnd,
    onDragStart,
    onDragMove,
    onSelect = noop,
    onHover,
    onOutHover,
}) => {
    const { id, x, y, availabilityType, type, name } = reservable

    const textName = useRef<Konva.Text | null>(null)
    const refGroup = useRef<Konva.Group | null>(null)

    const [hover, setHover] = useState(false)

    const imagePin = usePinsImages(type, availabilityType, !!selected)

    const containerColor = selected ? '#0019FF' : '#FFFFFF'

    const enterHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(true)
        refGroup.current?.moveToTop()
        onHover()
    }

    const outHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(false)
        onOutHover()
    }

    const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
        e.currentTarget.moveToTop()
        checkExistsFunction(onDragStart)(e)
    }

    const handleSelect = (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
        const stage = e.currentTarget.getStage()

        if (stage === null) {
            return
        }

        onSelect({
            reservable,
            posX: e.currentTarget.getAbsolutePosition().x + (width / 2) * stage.scaleX(),
            posY: e.currentTarget.getAbsolutePosition().y - 2 * stage.scaleX(),
            zoom: stage.scaleX(),
        })
    }

    const [width, setWidth] = useState(0)
    const height = ROOM_SIZES.HEIGHT_ROOM

    let textRoom = name || ''
    if (textRoom.length > ROOM_SIZES.MAX_LENGTH_NAME) {
        textRoom = textRoom.slice(0, ROOM_SIZES.MAX_LENGTH_NAME) + '...'
    }

    //set width
    useEffect(() => {
        const offsetText = ROOM_SIZES.WIDTH_IMAGE_BLOCK + ROOM_SIZES.PADDING_ROOM_X
        const widthText = textName.current ? textName.current?.textWidth + offsetText : 0

        setWidth(widthText)
    }, [name])

    return (
        <Group
            ref={refGroup}
            id={id}
            draggable
            onDragStart={handleDragStart}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
            x={x}
            y={y}
            scaleX={hover || selected ? 1.05 : 1}
            scaleY={hover || selected ? 1.05 : 1}
            offsetX={width / 2}
            offsetY={height}
        >
            <Rect
                width={width}
                height={height}
                cornerRadius={12}
                fill={containerColor}
                shadowColor="black"
                shadowBlur={15}
                shadowOpacity={0.2}
            />
            <Image image={imagePin} x={!name ? width / 2 - 10 : 12} y={10} />
            <Text
                ref={textName}
                height={40}
                align="center"
                x={ROOM_SIZES.WIDTH_IMAGE_BLOCK}
                verticalAlign="middle"
                text={textRoom}
                fontFamily="Poppins"
                fontSize={16}
                fill={selected ? '#FFFFFF' : '#333333'}
                wrap="none"
            />
            <Path x={width / 2 - 5} y={height - 0.5} data={pathData} fill={containerColor} />
            <Rect
                id={id}
                width={width}
                height={height}
                cornerRadius={12}
                onClick={handleSelect}
                onTouchEnd={handleSelect}
                onMouseEnter={enterHoverSeat}
                onMouseOut={outHoverSeat}
            />
        </Group>
    )
}
