import React, { FC, useState, ChangeEvent, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import NewPasswordInput from 'components/NewPasswordInput'
import { TitleAuth, DescriptionAuth } from 'containers/Auth/styles'
import { usePreloader, useAuth } from 'context'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled } from 'styles/buttons-styles'

const defaultFormFieldValue = { value: '', showPassword: false, isError: false }

type Props = {
    type: 'CONFIRM' | 'FORGOT'
}

//TODO: Refactor this component when we will have new Auth logic

const PasswordConfirmation: FC<Props> = ({ type }) => {
    const { t } = useTranslation(['translation'])
    const { email, tempPassword } = useParams<{ email: string; tempPassword: string }>()
    const { showPreloader } = usePreloader()
    const { completeNewPassword, forgotUserPasswordSubmit, signOut } = useAuth()
    const dispatch = useAppDispatch()

    const [password, setPassword] = useState(defaultFormFieldValue)

    const handleCreateAccount = useCallback(async () => {
        if (!password.value) {
            dispatch(messageActions.messageShown({ text: t('Please fill the password field'), severity: 'error' }))
            return
        }

        showPreloader(true)

        if (type === 'CONFIRM') {
            await completeNewPassword(email!, tempPassword!, password.value)
        } else {
            await forgotUserPasswordSubmit(email!, tempPassword!, password.value)
        }

        showPreloader(false)
    }, [password, type, email, tempPassword])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword((prevState) => ({ ...prevState, value: event.target.value, isError: false }))
    }

    return (
        <>
            <TitleAuth>{t('Password reset')}</TitleAuth>
            <DescriptionAuth className="margin_bottom_32">
                {t('Email')}: <b>{email}</b>
            </DescriptionAuth>
            <NewPasswordInput onEnter={handleCreateAccount} value={password.value} onChange={handleInputChange} />

            <ButtonFilled className="margin_top_16" width="100%" onClick={handleCreateAccount}>
                {type === 'FORGOT' ? 'Update password' : 'Set password'}
            </ButtonFilled>
        </>
    )
}

export default PasswordConfirmation
