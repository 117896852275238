import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment/moment'

import { MainEmployeeData } from 'types/data.types'

import { getAWSFormatDate, getHoursFromISODateString } from '../../../helpers'
import { getMobileSuggestedRoomTime } from '../helpers'
import { MobileOfficeWithRooms, MobileRoom, MobileRoomBooking } from '../types'

type MobileRoomsState = {
    date: string
    selectedRoom: MobileRoom | null
    selectedRoomMembers: Array<MainEmployeeData>
    selectedBookingTime: [string, string]
    selectedScheduleTime: [string, string]
    selectedRoomBookings: Array<MobileRoomBooking>
    titleRoomBooking: string
    descriptionRoomBooking: string
    editedRoomBookingID: string | null
    officesWithRooms: Array<MobileOfficeWithRooms>
}

export const initialState: MobileRoomsState = {
    date: getAWSFormatDate(moment()),
    selectedRoom: null,
    selectedRoomMembers: [],
    selectedBookingTime: ['00:00', '24:00'],
    selectedScheduleTime: ['00:00', '24:00'],
    selectedRoomBookings: [],
    titleRoomBooking: '',
    descriptionRoomBooking: '',
    editedRoomBookingID: null,
    officesWithRooms: [],
}

export const resetEditBooking: Partial<MobileRoomsState> = {
    selectedRoom: null,
    selectedRoomMembers: [],
    selectedBookingTime: ['00:00', '24:00'],
    titleRoomBooking: '',
    descriptionRoomBooking: '',
    editedRoomBookingID: null,
}

export const mobileRoomsSlice = createSlice({
    name: 'mobileRooms',
    initialState,
    reducers: {
        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },
        setOfficesWithRooms: (state, action: PayloadAction<Array<MobileOfficeWithRooms>>) => {
            return {
                ...state,
                officesWithRooms: action.payload,
            }
        },
        setSelectedRoom: (state, action: PayloadAction<MobileRoom>) => {
            return {
                ...state,
                selectedRoom: action.payload,
            }
        },
        setSelectedScheduleTime: (state, action: PayloadAction<[string, string]>) => {
            return {
                ...state,
                selectedScheduleTime: action.payload,
                selectedBookingTime: action.payload,
            }
        },
        setSelectedRoomMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedRoomMembers: action.payload,
            }
        },
        setSelectedBookingTime: (state, action: PayloadAction<[string, string]>) => {
            return {
                ...state,
                selectedBookingTime: action.payload,
            }
        },
        setTitleRoomBooking: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                titleRoomBooking: action.payload,
            }
        },
        setDescriptionRoomBooking: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                descriptionRoomBooking: action.payload,
            }
        },
        setEditRoomBooking: (state, action: PayloadAction<MobileRoomBooking | null>) => {
            if (action.payload === null) {
                return {
                    ...state,
                    ...resetEditBooking,
                }
            } else {
                return {
                    ...state,
                    editedRoomBookingID: action.payload.id,
                    titleRoomBooking: action.payload.title,
                    descriptionRoomBooking: action.payload.description,
                    selectedRoomMembers: action.payload.Employees.items.map((item) => item.Employee),
                    selectedBookingTime: [
                        getHoursFromISODateString(action.payload.startTime),
                        getHoursFromISODateString(action.payload.endTime),
                    ],
                }
            }
        },
        setSelectedRoomBookings: (state, action: PayloadAction<Array<MobileRoomBooking>>) => {
            const suggestedBookingTime = getMobileSuggestedRoomTime(action.payload, state.selectedScheduleTime)

            return {
                ...state,
                selectedRoomBookings: action.payload,
                selectedBookingTime: suggestedBookingTime as [string, string],
            }
        },
    },
})

export const mobileRoomsActions = mobileRoomsSlice.actions
export default mobileRoomsSlice.reducer
