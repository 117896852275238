import { FC, useRef, useState, useEffect } from 'react'

import Konva from 'konva'
import { Group, Image, Rect, Text } from 'react-konva'

import { AbsoluteCoords, EReservableType, PIN_CIRCLE } from 'types/data.types'

import TimeLineBooking from './TimeLineBooking'
import { ROOM_SIZES } from '../helpers/constants'
import { getSizePreviewHour, getSpaceScreenRoomSize } from '../helpers/getSizes'
import { useSpaceScreenPinsImages } from '../hooks/useSpaceScreenPinsImages'
import { HoverSpaceScreenSeat, ReservableSpaceScreen } from '../types'

type Props = {
    seat: ReservableSpaceScreen
    selected: boolean
    onSelect: (val: ReservableSpaceScreen) => void
    onHover: (hover: HoverSpaceScreenSeat | null) => void
    selectable?: boolean
}
export const RoomSpaceScreenReservable: FC<Props> = ({ seat, selected, onSelect, onHover, selectable = true }) => {
    const groupRef = useRef<Konva.Group | null>(null)
    const [hover, setHover] = useState(false)
    const {
        id,
        typeSeat,
        x,
        y,
        nameRoom,
        preview,
        startSpaceWorkingTime,
        endSpaceWorkingTime,
        selectable: selectableReservable,
    } = seat

    const noSelectable = !selectable || !selectableReservable

    const { imagePin } = useSpaceScreenPinsImages(EReservableType.ROOM, typeSeat, selected, noSelectable)
    const size = getSpaceScreenRoomSize(typeSeat.pinType)

    const sizePreviewHour = preview ? getSizePreviewHour(size.width, preview.length) : undefined

    const [prevewDots, setPrevewDots] = useState<PIN_CIRCLE[] | null>(null)

    const contentColor = selected ? '#0019FF' : '#333333'

    const enterHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(true)
        const stage = e.currentTarget.getStage()

        if (stage === null) {
            return
        }

        groupRef.current && groupRef.current.moveToTop()

        if (!noSelectable) {
            stage.container().style.cursor = 'pointer'
        } else {
            stage.container().style.cursor = 'not-allowed'
        }

        const coords: AbsoluteCoords = {
            x: e.currentTarget.getAbsolutePosition().x + (size.width / 2) * stage.scaleX(),
            y: e.currentTarget.getAbsolutePosition().y,
            offset: 8 * stage.scaleX(),
        }
        onHover({ seat, coords })
    }

    const outHoverSeat = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setHover(false)
        const stage = e.currentTarget.getStage()

        if (stage) {
            stage.container().style.cursor = 'auto'
        }
        onHover(null)
    }

    const handleSelect = () => {
        if (noSelectable) {
            return
        }
        onSelect(seat)
    }

    useEffect(() => {
        setPrevewDots(preview)
    }, [preview])

    return (
        <Group
            ref={groupRef}
            id={id}
            x={x}
            y={y}
            scaleX={hover || selected ? 1.05 : 1}
            scaleY={hover || selected ? 1.05 : 1}
            offsetY={size.height}
            offsetX={size.width / 2}
        >
            <Image id={id} image={imagePin} shadowColor="black" />
            <Text
                width={size.width - ROOM_SIZES.PADDING_ROOM_X * 2}
                height={21}
                align="center"
                verticalAlign="middle"
                ellipsis
                text={nameRoom}
                fontFamily="Poppins"
                fontSize={16}
                stroke={contentColor}
                strokeWidth={0.5}
                fill={contentColor}
                wrap="none"
                x={ROOM_SIZES.PADDING_ROOM_X}
                y={8}
            />

            {prevewDots && (
                <TimeLineBooking
                    offsetX={ROOM_SIZES.PADDING_ROOM_X}
                    offsetY={39}
                    previewHours={prevewDots}
                    sizeCircle={sizePreviewHour}
                    color={contentColor}
                />
            )}

            <Text
                x={ROOM_SIZES.PADDING_ROOM_X}
                y={45}
                height={14}
                verticalAlign="middle"
                text={startSpaceWorkingTime}
                fontFamily="Poppins"
                fontSize={9}
                fill={contentColor}
                wrap="none"
            />

            <Text
                x={size.width - ROOM_SIZES.PADDING_ROOM_X - 25}
                y={45}
                height={14}
                verticalAlign="middle"
                text={endSpaceWorkingTime}
                fontFamily="Poppins"
                fontSize={9}
                fill={contentColor}
                wrap="none"
            />

            <Rect
                id={id}
                width={size.width}
                height={size.height}
                fill="transparent"
                onMouseEnter={enterHoverSeat}
                onMouseOut={outHoverSeat}
                onClick={handleSelect}
                onTouchEnd={handleSelect}
            />
        </Group>
    )
}
