import { FC, useState, useCallback } from 'react'

import { useMutation } from '@apollo/client'
import Konva from 'konva'
import { useTranslation } from 'react-i18next'
import { Star } from 'react-konva'

import { MUTATION_UPDATE_EMPLOYEE } from 'graphql/mutations'
import { useAppDispatch, useAppSelector } from 'hooks'
import { messageActions } from 'store/slices/message'
import { AtLeast } from 'types/common.types'
import { TEmployee, AbsoluteCoords } from 'types/data.types'

import analytics, { AnalyticsEvents } from '../../../../helpers/analytics'
import { HoverSpaceScreenSeatStar } from '../types'

type Props = {
    size: {
        width: number
        height: number
        radius: number
        avatar: number
    }
    selected?: boolean
    offsetX?: number
    offsetY?: number
    reservableID: string
    onHoverStar?: (hover: HoverSpaceScreenSeatStar | null) => void
}

const FavoriteSeat: FC<Props> = ({ reservableID, offsetX, offsetY, onHoverStar }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation('translation')

    const employeeID = useAppSelector(({ user }) => user.id)
    const favouriteReservableID = useAppSelector(({ user }) => user.favouriteReservableID)
    const [isHovered, setIsHovered] = useState(false)
    const isActive = favouriteReservableID === reservableID
    const fillColor = isHovered || isActive ? '#FFBB0B' : '#E3E8EE'
    const strokeColor = '#E8FBEF'

    const [updateEmployee, { loading }] = useMutation<
        TEmployee,
        { input: AtLeast<TEmployee, 'id' | 'favouriteReservableID'> }
    >(MUTATION_UPDATE_EMPLOYEE, {
        onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
    })

    const handleMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setIsHovered(true)

        // onHover && onHover(true)
        const stage = e.currentTarget.getStage()

        if (stage === null) {
            return
        }

        stage.container().style.cursor = 'pointer'

        const coords: AbsoluteCoords = {
            x: e.currentTarget.getAbsolutePosition().x,
            y: e.currentTarget.getAbsolutePosition().y,
            offset: -40,
        }

        if (onHoverStar) {
            onHoverStar({
                title: t(isActive ? 'Favorite' : 'Mark as favorite') as string,
                coords,
            })
        }
    }

    const handleMouseOut = (e: Konva.KonvaEventObject<MouseEvent>) => {
        setIsHovered(false)
        if (onHoverStar) {
            onHoverStar(null)
        }

        const stage = e.currentTarget.getStage()

        if (stage === null) {
            return
        }

        stage.container().style.cursor = 'auto'
    }

    const handleClick = useCallback(async () => {
        if (employeeID && !loading) {
            await updateEmployee({
                variables: {
                    input: {
                        id: employeeID,
                        favouriteReservableID: isActive ? null : reservableID,
                    },
                },
            })
            analytics.logEvent(AnalyticsEvents.SET_FAVORITE_SEAT)
        }
    }, [employeeID, reservableID, loading, isActive])

    return (
        <Star
            x={offsetX}
            y={offsetY}
            numPoints={5}
            innerRadius={5}
            outerRadius={9}
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth={1}
            rotation={0}
            onMouseEnter={handleMouseEnter}
            onMouseOut={handleMouseOut}
            onClick={handleClick}
        />
    )
}

export default FavoriteSeat
