import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import styled from 'styled-components'

type Props = {
    imgSize: number
    fontSize: number
} & AvatarProps

export const AvatarRoot = styled(({ imgSize, fontSize, ...other }: Props) => <Avatar {...other} />)`
    &.MuiAvatar-root {
        background-color: #333333;
        height: ${(props) => props.imgSize}px;
        width: ${(props) => props.imgSize}px;
        font-size: ${(props) => props.fontSize}px;

        img {
            height: calc(100% + 1px);
        }
    }
`
