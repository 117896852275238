import React from 'react'

import logo from 'assets/common/logo-yoffix.svg'
import LanguageSwitcher from 'components/LanguageSwitcher'

import { AuthLogo, HeaderContainer } from './styles'

const AuthHeader = () => {
    return (
        <HeaderContainer>
            <AuthLogo src={logo} alt="Yoffix" />
            <LanguageSwitcher />
        </HeaderContainer>
    )
}

export default AuthHeader
