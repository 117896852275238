import React, { FC, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { TickSVG, CrossSVG } from 'assets/common'
import Tag, { TAG_TYPES } from 'components/atoms/Tag'
import { noop } from 'helpers'
import { DefaultSalesEmail } from 'shared/constants'
import * as T from 'types/billing.types'
import { BillingType } from 'types/billing.types'

import * as S from './styles'
import CancelAlert from '../CancelAlert'

type Props = {
    title: string
    price: string
    billingType: BillingType
    subscriptionPlan?: T.SubscriptionsPlanForCompany | T.TrialType
    features: Array<{ [key: string]: T.FeatureStatusType }>
    onClick?: (status: T.BillingSubscriptionStatus | null) => void
    isLoading?: boolean
    isDeprecated?: boolean
}

const getSubscriptionStatusText = (type: T.BillingSubscriptionStatus, expiresAt: string) => {
    switch (type) {
        case 'TRIAL':
            return {
                text: `${Math.round(
                    (new Date(expiresAt).getTime() - new Date().getTime()) / 60000 / 60 / 24,
                )} days of the trial left`,
                color: TAG_TYPES.WHITE,
            }
        case 'ACTIVE':
            return { text: 'Current plan', color: TAG_TYPES.WHITE }
        case 'TRIAL_EXPIRED':
            return { text: 'trial expired', color: TAG_TYPES.NEGATIVE_RED }
        default:
            return
    }
}

const BillingCard: FC<Props> = ({
    price,
    title,
    subscriptionPlan,
    features,
    onClick = noop,
    isLoading,
    billingType,
    isDeprecated,
}) => {
    const { t } = useTranslation('translation')
    const [open, setOpen] = useState(false)

    const handleClick = (status?: T.BillingSubscriptionStatus) => {
        if (status !== undefined) {
            onClick(status)
        }
        setOpen(false)
    }

    const tagContent =
        subscriptionPlan && getSubscriptionStatusText(subscriptionPlan.status, subscriptionPlan.expiresAt)

    let currentPrice

    currentPrice =
        !isNaN(+price) &&
        subscriptionPlan &&
        'discount' in subscriptionPlan &&
        subscriptionPlan.discount?.type === T.DISCOUNT.PERCENTAGE
            ? Math.trunc((1 - subscriptionPlan.discount.value) * +price * 100) / 100
            : price

    if (billingType === BillingType.YEAR) {
        currentPrice = Math.trunc((+currentPrice / 12) * 100) / 100
    }

    currentPrice = currentPrice !== 'Custom' ? `€ ${currentPrice}` : t('Custom')

    const getBtn = () => {
        if (title === 'Enterprise') {
            return (
                <S.ButtonOutlinedWrapp className="flex-center">
                    <a href={`mailto:${DefaultSalesEmail}?subject=Enterprise plan`} className="flex-center">
                        {t('Contact sales')}
                    </a>
                </S.ButtonOutlinedWrapp>
            )
        }

        switch (subscriptionPlan?.status) {
            case 'TRIAL':
            case 'EXTERNAL_CANCELLED':
            case 'TRIAL_EXPIRED':
                if (isDeprecated) {
                    return null
                } else {
                    return (
                        <S.ButtonFilledWrapp
                            onClick={() => !isLoading && onClick(subscriptionPlan.status)}
                            className="flex-center"
                        >
                            {isLoading && <S.ButtonLoader size={16} />}
                            {t('Upgrade')}
                        </S.ButtonFilledWrapp>
                    )
                }
            case 'ACTIVE':
                return (
                    <S.ButtonFilledWrapp
                        isCancel={subscriptionPlan.status === 'ACTIVE'}
                        onClick={() => !isLoading && setOpen(true)}
                        className="flex-center"
                    >
                        {isLoading && <S.ButtonLoader size={16} style={{ color: TAG_TYPES.NEGATIVE_RED }} />}
                        {t('Cancel subscription')}
                    </S.ButtonFilledWrapp>
                )
            default:
                if (isDeprecated) {
                    return null
                } else {
                    return (
                        <S.ButtonFilledWrapp onClick={() => !isLoading && onClick(null)} className="flex-center">
                            {isLoading && <S.ButtonLoader size={16} />}
                            {t('Start 14-days trial')}
                        </S.ButtonFilledWrapp>
                    )
                }
        }
    }

    return (
        <>
            <S.BillingCardContainer>
                <S.BillingCardTitleWrapp>
                    <S.BillingCardTitle>{t(title)}</S.BillingCardTitle>
                    {tagContent && <Tag type={tagContent.color} text={t(tagContent.text)} />}
                </S.BillingCardTitleWrapp>
                <S.BillingCardPriceWrapp>
                    <S.BillingCardPrice>{currentPrice}</S.BillingCardPrice>
                    <S.BillingCardDivider />
                    <S.BillingCardPeriod>
                        {t('per user')}
                        <br />
                        {t('per month')}
                    </S.BillingCardPeriod>
                </S.BillingCardPriceWrapp>
                <S.BollingCardFeatureWrapper>
                    {getBtn()}
                    <S.BillingCardFeaturesBlock>
                        {features.map((feature) => {
                            const key = Object.keys(feature)[0]
                            const type = Object.values(feature)[0]

                            return (
                                <S.BillingCardFeature key={key} type={type}>
                                    {type === 'close' ? <CrossSVG /> : <TickSVG />}
                                    {t(key)}
                                    {type === 'soon' && (
                                        <S.BillingCardTagWrapp>
                                            <Tag type={TAG_TYPES.YELLOW} text={t('soon', { ns: 'translation' })} />
                                        </S.BillingCardTagWrapp>
                                    )}
                                </S.BillingCardFeature>
                            )
                        })}
                    </S.BillingCardFeaturesBlock>
                </S.BollingCardFeatureWrapper>
            </S.BillingCardContainer>

            {subscriptionPlan?.status === 'ACTIVE' && (
                <CancelAlert
                    isOpen={open}
                    expiresAt={subscriptionPlan.expiresAt}
                    quantity={(subscriptionPlan as T.SubscriptionsPlanForCompany).quantity}
                    onConfirm={() => handleClick(subscriptionPlan?.status)}
                    onCancel={() => handleClick()}
                />
            )}
        </>
    )
}

export default BillingCard
