import styled, { css } from 'styled-components'

export const Root = styled.div<{ withOutMargin?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: ${({ withOutMargin }) => (withOutMargin ? 0 : '12px')};
`

export const InputContainer = styled.div<{
    minHeight: number
    maxWidth?: number
    dynamicWidth: boolean
    hasError?: boolean
    background?: string
    type: 'input' | 'textarea'
    withOutMargin?: boolean
}>`
    background-color: ${(props) => props.background || 'rgba(227, 232, 238, 0.5)'};
    border-radius: 12px;
    margin-bottom: ${({ withOutMargin }) => (withOutMargin ? 0 : '24px')};
    min-height: ${(props) => props.minHeight}px;
    border: 1px solid transparent;

    & .Mui-error.MuiFormHelperText-root {
        top: 60px;
        left: 16px;
        position: absolute;
        margin: 0;
        color: ${(props) => props.theme.color.coral};
    }

    .MuiFormControl-root {
        width: 100%;
        height: 100%;
        min-height: inherit;

        svg {
            width: 100%;
            height: 100%;
        }

        .MuiInputBase-root {
            input,
            textarea {
                padding: 0;
                color: ${(props) => props.theme.color.black};
                line-height: 21px;
                display: flex;

                &:focus {
                    caret-color: ${(props) => props.theme.color.blueButton};
                }
            }
        }
    }

    .MuiInputBase-root {
        min-height: inherit;
        padding: 14px 16px;
    }

    .MuiFormControl-root label {
        font-size: 14px;
        left: 16px;
        color: rgba(51, 51, 51, 0.5);
    }

    .MuiFormControl-root .MuiInputLabel-shrink {
        top: 8px;
        transform: translate(0, 0) scale(0.875);
    }

    .MuiInputLabel-animated {
        transition: color 150ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms,
            top 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    ${(props) =>
        props.hasError &&
        css`
            .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-formControl.Mui-error {
                bottom: 8px;
                top: auto;
                transform: none;
            }

            .Mui-error.MuiInputLabel-shrink {
                display: none;
            }

            border: 1px solid ${props.theme.color.coral};
        `}

    ${(props) =>
        props.type === 'input' &&
        css`
            .MuiFormControl-root {
                .MuiInputBase-root {
                    margin: 0;

                    input {
                        height: auto;
                        width: ${props.maxWidth ? `${props.maxWidth}px` : '100%'};
                        position: ${props.dynamicWidth ? 'absolute' : 'relative'};
                    }
                }

                label {
                    transform: translate(0, -50%);
                    top: 50%;
                }

                .MuiInputLabel-root.Mui-error {
                    transform: translateY(100%);
                    line-height: 20px;
                }
            }
        `}
    
    ${(props) =>
        props.type === 'textarea' &&
        css`
            .MuiFormControl-root {
                min-height: inherit;

                .MuiInputBase-root {
                    overflow: auto;
                    margin-top: 0;

                    .MuiInputBase-inputMultiline {
                        margin-top: 16px;
                        align-self: flex-start;
                        overflow: auto !important;
                    }
                }

                label {
                    top: 17px;
                    transform: translate(0, 0);
                }
            }
        `}
`

export const ExternalLabel = styled.div<{ empty?: boolean }>`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: ${(props) => (props.empty ? 'transparent' : 'rgba(51, 51, 51, 0.5)')};
`
