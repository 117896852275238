import React from 'react'

import CheckMark from 'assets/auth/Advantages/ChackMark.svg'

import { AdvantagesContainer, AdvantagesTitle, AdvantagesList, AdvantagesListItem } from './style'

const Advantages = () => (
    <AdvantagesContainer>
        <AdvantagesTitle>
            Hybrid work.
            <br /> Simplified.
        </AdvantagesTitle>
        <AdvantagesList>
            <AdvantagesListItem>
                <img src={CheckMark} alt="check mark" loading="lazy" />
                Workweek scheduling
            </AdvantagesListItem>
            <AdvantagesListItem>
                <img src={CheckMark} alt="check mark" loading="lazy" />
                Desk, Room, Parking slot booking
            </AdvantagesListItem>
            <AdvantagesListItem>
                <img src={CheckMark} alt="check mark" loading="lazy" />
                Office use optimization
            </AdvantagesListItem>
        </AdvantagesList>
    </AdvantagesContainer>
)

export default Advantages
