import React, { FC } from 'react'

import { useResizeDevice } from 'context'
import { EUserGroup } from 'types/user.types'

import DesktopSidebar from './components/DesktopSidebar'
import MobileSidebar from './components/MobileSidebar'

type Props = {
    pathname: string
    userGroup: EUserGroup
}

const Sidebar: FC<Props> = ({ pathname, userGroup }) => {
    const { isMobile } = useResizeDevice()

    return (
        <>
            {isMobile ? (
                <MobileSidebar pathname={pathname} />
            ) : (
                <DesktopSidebar pathname={pathname} userGroup={userGroup} />
            )}
        </>
    )
}

Sidebar.displayName = 'Sidebar'

export default Sidebar
