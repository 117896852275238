import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

import { EUserGroup } from '../types/user.types'

export const getUserSessionGroup = async (user: CognitoUser): Promise<EUserGroup[] | null> => {
    return new Promise((resolve) => {
        user.getSession((_err: Error | null, session: CognitoUserSession | null) => {
            if (session) {
                const sessionGroup = session.getAccessToken().payload['cognito:groups']

                return resolve(sessionGroup || null)
            }

            return resolve(null)
        })
    })
}
