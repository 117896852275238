import styled, { css } from 'styled-components'

export const BigSwitchWrap = styled.div`
    display: flex;
    justify-content: space-between;
    height: 44px;
    border-radius: 16px;
    padding: 1px;
    box-shadow: 0 0 0 1px #e3e8ee;
`
export const FilterSwitchButton = styled.div<{ selected: boolean; isMobile: boolean }>`
    padding-inline: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    color: ${(props) => props.theme.color.black};
    font-weight: 500;
    font-size: 14px;

    cursor: pointer;

    &:last-child {
        margin-left: 1px;
    }

    svg {
        fill: ${(props) => props.theme.color.black};
        fill-opacity: 1;

        margin-right: 9px;
    }

    ${(props) =>
        props.selected &&
        css`
            background: ${props.theme.color.black};
            color: #ffffff;

            svg {
                fill: #ffffff;
            }
        `}

    :hover {
        ${(props) =>
            !props.selected &&
            !props.isMobile &&
            css`
                background: #f1f3f6;
            `}
    }
`
