import styled from 'styled-components'

export const TagContainer = styled.div`
    padding: 6px 12px;
    font-size: 12px;
    line-height: 17px;
    color: rgba(51, 51, 51, 0.5);
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    height: min-content;
    width: max-content;
`

export const TagAmountWrapp = styled.span`
    display: block;
    margin-right: 6px;
`

export const TagText = styled.span`
    color: ${(props) => props.theme.color.black};
`
