import { getHoursFromISODateString } from './utils'
import * as T from '../types/data.types'

const sortBookingsByStartTime = (bookings: Array<T.PickerReservableBooking>, sortByTime = false) => {
    const compareStartTime = (prev: T.PickerReservableBooking, next: T.PickerReservableBooking) => {
        const prevStartTime = sortByTime ? getHoursFromISODateString(prev?.startTime) : prev?.startTime
        const nextStartTime = sortByTime ? getHoursFromISODateString(next?.startTime) : next?.startTime

        if (prevStartTime < nextStartTime) {
            return -1
        }
        if (prevStartTime > nextStartTime) {
            return 1
        }
        return 0
    }

    return [...bookings].sort(compareStartTime)
}

export default sortBookingsByStartTime
