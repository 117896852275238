import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { GmailSVG, OutlookSVG } from 'assets/common'
import { DescriptionAuth, TitleAuth } from 'containers/Auth/styles'
import { CHECK_EMAIL_SEARCH_TYPE } from 'containers/Auth/types'
import { getQueryParams } from 'helpers'
import { ButtonString, LinkOutlined } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

import { BlueArrowLeft } from '../../styles'

const GMAIL_URL = 'https://mail.google.com/mail/'
const OUTLOOK_URL = 'https://outlook.live.com/owa/' //This url might be wrong

const CheckEmailSingUp = () => {
    const { t } = useTranslation('translation')
    const location = useLocation()
    const params = getQueryParams(location.search, ['email', 'type'])

    const { email, type, backButton } = location?.state?.email ? location.state : params

    if (!type) {
        return null
    }

    return (
        <>
            <TitleAuth>{t('Check your email!')}</TitleAuth>
            <DescriptionAuth className="margin_bottom_40">
                {type === CHECK_EMAIL_SEARCH_TYPE.PASSWORD && (
                    <>
                        {t(
                            "We've sent password reset instructions to your email address. If no email is received within ten minutes, check that the submitted email is correct.",
                        )}
                    </>
                )}
                {type === CHECK_EMAIL_SEARCH_TYPE.REGISTER && (
                    <>
                        {t('We’ve sent a verification link to')}
                        <br />
                        <b>{email}</b>
                    </>
                )}
            </DescriptionAuth>
            <div className="flex-space-between margin_bottom_24">
                <LinkOutlined width="48%" href={GMAIL_URL}>
                    <GmailSVG /> {t('Open Gmail')}
                </LinkOutlined>
                <LinkOutlined width="48%" href={OUTLOOK_URL}>
                    <OutlookSVG /> {t('Open Outlook')}
                </LinkOutlined>
            </div>
            <DescriptionAuth className="margin_left_content info_gray">
                {t('Can’t find email from us? Check your spam folder!')}
            </DescriptionAuth>
            {backButton && (
                <Link to={backButton.url}>
                    <ButtonString className="margin_left_content login_small">
                        <BlueArrowLeft />
                        {backButton.text}
                    </ButtonString>
                </Link>
            )}
            {!backButton && type === CHECK_EMAIL_SEARCH_TYPE.REGISTER && (
                <Link to={AUTH_ROUTES.SIGNUP}>
                    <ButtonString className="margin_left_content login_small">
                        <BlueArrowLeft />
                        {t('Back to Sign up')}
                    </ButtonString>
                </Link>
            )}
            {!backButton && type === CHECK_EMAIL_SEARCH_TYPE.PASSWORD && (
                <Link to={AUTH_ROUTES.LOGIN}>
                    <ButtonString className="margin_left_content login_small">
                        <BlueArrowLeft />
                        {t('Back to Log in')}
                    </ButtonString>
                </Link>
            )}
        </>
    )
}

export default CheckEmailSingUp
