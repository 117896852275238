import React, { useEffect } from 'react'

import { ApolloProvider } from '@apollo/client'
import { Auth as AmplifyAuth } from '@aws-amplify/auth'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { app } from '@microsoft/teams-js'
import { Amplify } from 'aws-amplify'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useNavigate, useLocation, BrowserRouter as Router } from 'react-router-dom'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'

import { Loader } from 'components/atoms'
import Message from 'components/atoms/Message'
import { MsTeamsProvider, useMsTeams } from 'context/msTeams'
import GlobalErrorHandler from 'helpers/GlobalErrorHandler'
import { usePaddle, useAutoLogout, useAppDispatch } from 'hooks'

/* from aliases */
import Auth from './containers/Auth'
import { useAuth, AuthProvider } from './context/auth'
import { PreloaderProvider } from './context/preloader'
import { ResizeProvider } from './context/resize'
import { createClient } from './graphql/client-new'
import EmployeeTemplate from './templates/employee'
import ManagerTemplate from './templates/manager'
import theme from './theme'
import { EUserGroup } from './types/user.types'

const domain = window.location.hostname

AmplifyAuth.configure({
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
        domain,
        path: '/',
        expires: 365,
        sameSite: 'strict',
        secure: false,
    },
})

Amplify.configure({
    API: {
        aws_cognito_region: process.env.REACT_APP_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
        aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
        aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
        aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, // (optional) - Amazon Cognito Identity Pool ID
        // aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
        endpoints: [
            {
                name: 'integration-calendar',
                endpoint: process.env.REACT_APP_API_URL,
            },
            {
                name: 'saas-subscription',
                endpoint: process.env.REACT_APP_API_URL,
            },
            {
                name: 'list-microsoft-users',
                endpoint: process.env.REACT_APP_API_URL,
            },
            {
                name: 'demo-account',
                endpoint: process.env.REACT_APP_API_URL,
            },
            {
                name: 'checkin-service',
                endpoint: process.env.REACT_APP_API_URL,
            },
        ],
    },
})

const apolloClient = createClient({ connectToDevTools: process.env.REACT_APP_NODE_ENV === 'development' })

const App = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const ldClient = useLDClient()
    const { isAuth, userGroup, userAuth } = useAuth()
    const { boot, update, startTour } = useIntercom()
    const { inTeams } = useMsTeams()

    usePaddle()
    useAutoLogout()

    if (!inTeams) {
        boot()
    }

    useEffect(() => {
        if (!inTeams) {
            if (isAuth) {
                update({
                    customLauncherSelector: '#intercom-launcher',
                    hideDefaultLauncher: true,
                    customAttributes: {
                        userRole: userGroup,
                    },
                })
            }
        }

        if (isAuth && userAuth) {
            if (inTeams) {
                const urlParams = new URLSearchParams(window.location.search)
                console.log('[YOFFIX] App', inTeams, urlParams)
                ;(async () => {
                    if (window.location.pathname === '/initial-setup') {
                        return
                    }

                    const redirectUrl = urlParams.get('redirect_uri') || 'calendar'

                    console.log('[Yoffix]', redirectUrl)

                    app.notifyAppLoaded()
                    app.notifySuccess()

                    navigate(`/${redirectUrl}`)
                })()
            } else {
                ldClient?.identify(
                    {
                        key: userAuth.id,
                        custom: {
                            companyId: userAuth.companyId,
                        },
                    },
                    undefined,
                    (err, flags) => {
                        console.log('LD flags', flags)
                        console.log('LD err', err)
                    },
                )

                const startPathname = localStorage.getItem('pathname')

                if (startPathname) {
                    localStorage.removeItem('pathname')
                    navigate(startPathname)
                }
            }
        } else {
            // Save the current location to redirect after login
            const { pathname, search } = location

            localStorage.setItem('pathname', `${pathname}${search}`)
        }
    }, [isAuth, inTeams])

    return (
        <>
            {isAuth && userGroup === EUserGroup.MANAGER && <ManagerTemplate />}
            {isAuth && userGroup === EUserGroup.EMPLOYEE && <EmployeeTemplate />}
            {isAuth && userGroup === EUserGroup.TEAM_LEAD && <EmployeeTemplate />}
            {!isAuth && <Auth />}
        </>
    )
}

const AppContainer = () => {
    const dispatch = useAppDispatch()

    return (
        <MsTeamsProvider>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
                <Router>
                    <GlobalErrorHandler>
                        <PreloaderProvider>
                            <ResizeProvider>
                                <AuthProvider dispatch={dispatch}>
                                    <ThemeProviderStyled theme={theme}>
                                        <ThemeProvider theme={theme}>
                                            <ApolloProvider client={apolloClient}>
                                                <CssBaseline />
                                                <Message />
                                                <Loader />
                                                <App />
                                            </ApolloProvider>
                                        </ThemeProvider>
                                    </ThemeProviderStyled>
                                </AuthProvider>
                            </ResizeProvider>
                        </PreloaderProvider>
                    </GlobalErrorHandler>
                </Router>
            </IntercomProvider>
        </MsTeamsProvider>
    )
}

export default AppContainer
