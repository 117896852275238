import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

export const getNewToken = async (): Promise<string> => {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()

    const tokenIdJwt: string = await new Promise((resolve, reject) => {
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: Error, session: CognitoUserSession) => {
            if (err) {
                reject(err)
            }
            resolve(session.getIdToken().getJwtToken() || '')
        })
    })

    return tokenIdJwt
}
