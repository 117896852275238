import styled from 'styled-components'

export const DemoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 16px;
    margin-top: 24px;
`

export const DemoBlockText = styled.span`
    font-size: 14px;
    line-height: 21px;
    color: #333333cc;
`
