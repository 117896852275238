import { FC, useState, useEffect, useCallback } from 'react'

import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { app, authentication } from '@microsoft/teams-js'
import axios from 'axios'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'

import { GmailSVG, OutlookSVG } from 'assets/common'
import logo from 'assets/common/logo-yoffix.svg'
import { IconRocketSVG, IconPhoneSVG, IconSearchSVG } from 'assets/icons'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { useMsTeams } from 'context/msTeams'
import { ButtonOrange, LinkOutlined } from 'styles/buttons-styles'

import { ContactForm } from './ContactForm'
import * as S from './styles'
import AuthFooter from '../../components/AuthFooter'

const API_URL = process.env.REACT_APP_API_URL
const GMAIL_URL = 'https://mail.google.com/mail/'
const OUTLOOK_URL = 'https://outlook.live.com/owa/' //This url might be wrong
enum ScreenType {
    LOGIN_FAILED = 'LOGIN_FAILED',
    CREATE_DEMO_ACCOUNT = 'CREATE_DEMO_ACCOUNT',
    DEMO_ACCOUNT_CREATED = 'DEMO_ACCOUNT_CREATED',
}

export const MSTeamsLoginPage: FC = () => {
    const { inTeams, context } = useMsTeams()
    console.log('[YOFFIX] MSTeamsLoginPage', inTeams, context)

    const { t } = useTranslation('translation')
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [accessToken, setAccessToken] = useState<string>()
    const [userEmail, setUserEmail] = useState<string | null>(null)
    const [isAdmin] = useState<boolean>(true)
    const [screenType, setScreenType] = useState<ScreenType>(ScreenType.LOGIN_FAILED)

    const authenticate = useCallback(async () => {
        if (context) {
            const token = await authentication.getAuthToken()

            if (context.user?.tenant === undefined) {
                throw new Error('User not found')
            }

            setUserEmail(context.user?.userPrincipalName || null)

            const urlParams = new URLSearchParams(window.location.search)

            const params = new URLSearchParams({
                token,
                tenant_id: context.user?.tenant.id,
                redirect_uri: urlParams.get('redirect_uri') || 'calendar',
            })

            await axios.get(`${API_URL}/ms-teams/find-user?${params}`)

            setIsLoading(false)

            window.location.href = `${API_URL}/ms-teams/oauth/login?${params}`
        }
    }, [setUserEmail, setIsLoading, context])

    useEffect(() => {
        ;(async () => {
            if (inTeams && context) {
                try {
                    setIsLoading(true)
                    await authenticate()
                    app.notifyAppLoaded()
                    app.notifySuccess()
                } catch (e) {
                    console.log('[YOFFIX] error:', e)
                    app.notifyAppLoaded()
                    app.notifySuccess()
                    setIsError(true)
                    app.notifyFailure({
                        reason: app.FailedReason.Other,
                        message: 'App initialization failed',
                    })
                    setIsLoading(false)
                }
            }
        })()
    }, [inTeams, context])

    useEffect(() => {
        ;(async () => {
            if (accessToken) {
                await authenticate()
            }
        })()
    }, [accessToken])

    const handleButtonClick = useCallback(async () => {
        setIsLoading(true)

        const response = await axios.post(`${API_URL}/ms-teams/create-new-company/get-url`, {
            userEmail,
        })

        const result = await authentication.authenticate({
            url: response.data.location,
            width: 1400,
            height: 800,
            isExternal: false,
        })

        setAccessToken(result)
    }, [userEmail])

    const handleChangeScreenType = useCallback((type: ScreenType) => {
        setScreenType(type)
    }, [])

    return (
        <S.PageContainer>
            {isError && !isLoading && (
                <S.LoginFormContainer>
                    <S.FirstRow>
                        <S.LogoContainer>
                            <a href="https://yoffix.com">
                                <S.Logo src={logo} alt="Yoffix logo" />
                            </a>
                        </S.LogoContainer>
                        <S.LanguageSwitcherContainer>
                            <LanguageSwitcher />
                        </S.LanguageSwitcherContainer>
                    </S.FirstRow>
                    {screenType === ScreenType.LOGIN_FAILED && (
                        <div>
                            <S.WelcomeText>
                                {t('Welcome to')} <br /> Yoffix
                            </S.WelcomeText>
                            {isAdmin && (
                                <>
                                    <S.Text style={{ marginTop: 16 }}>
                                        {t('You are an admin? Start your trial right now!')}
                                    </S.Text>
                                    <S.CentralRow style={{ marginTop: 24 }}>
                                        <ButtonOrange onClick={() => handleButtonClick()} width="100%">
                                            <S.IconContainer>
                                                <IconRocketSVG />
                                            </S.IconContainer>{' '}
                                            {t('Start free trial')}
                                        </ButtonOrange>
                                    </S.CentralRow>
                                </>
                            )}
                            <S.Text style={{ marginTop: 40 }}>{t('If you`re not an administrator, you can:')}</S.Text>
                            <LinkOutlined
                                height="56px"
                                style={{ marginTop: 24, fontWeight: 400 }}
                                onClick={() => handleChangeScreenType(ScreenType.CREATE_DEMO_ACCOUNT)}
                            >
                                <S.IconContainer>
                                    <IconSearchSVG />
                                </S.IconContainer>
                                {t('Explore demo account')}
                            </LinkOutlined>
                            <S.SecondaryText style={{ marginTop: 8 }} isCentered>
                                {t('or')}
                            </S.SecondaryText>
                            <LinkOutlined
                                height="56px"
                                style={{ marginTop: 8, fontWeight: 400 }}
                                href={`https://yoffix.com/${i18n.language === 'de' ? 'de' : ''}/book-a-demo`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <S.IconContainer>
                                    <IconPhoneSVG />
                                </S.IconContainer>
                                {t('Book demo call')}
                            </LinkOutlined>
                        </div>
                    )}
                    {screenType === ScreenType.CREATE_DEMO_ACCOUNT && (
                        <div>
                            <S.WelcomeText>
                                {t('Request for demo access')} <br /> Yoffix
                            </S.WelcomeText>
                            <ContactForm
                                email={userEmail || ''}
                                onSuccessfulSubmit={() => handleChangeScreenType(ScreenType.DEMO_ACCOUNT_CREATED)}
                            />
                        </div>
                    )}
                    {screenType === ScreenType.DEMO_ACCOUNT_CREATED && (
                        <div>
                            <S.SuccessText>{t('Check your email')}!</S.SuccessText>
                            <S.MainText style={{ marginTop: 12 }}>
                                {t('We have sent you credentials for your demo account.')}
                            </S.MainText>
                            <div className="flex-space-between margin_bottom_24" style={{ marginTop: 40 }}>
                                <LinkOutlined width="48%" href={GMAIL_URL}>
                                    <GmailSVG /> {t('Open')} Gmail
                                </LinkOutlined>
                                <LinkOutlined width="48%" href={OUTLOOK_URL}>
                                    <OutlookSVG /> {t('Open')} Outlook
                                </LinkOutlined>
                            </div>
                            <S.SecondarySmallText style={{ marginTop: 24, marginBottom: 200 }} isCentered>
                                {t('Can’t find email from us? Check your spam folder!')}
                            </S.SecondarySmallText>
                        </div>
                    )}

                    <AuthFooter style={{ marginTop: 26 }} />
                </S.LoginFormContainer>
            )}
            {isLoading && (
                <S.LoaderWrapper className="flex-center preloader">
                    <Box position="relative" display="inline-flex">
                        <CircularProgress size={40} />
                    </Box>
                </S.LoaderWrapper>
            )}
        </S.PageContainer>
    )
}
