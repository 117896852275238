import styled, { css } from 'styled-components'

const IconMark =
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMC4zMzMwMDhDMy4zMiAwLjMzMzAwOCAwLjMzMzMzMiAzLjMxOTY3IDAuMzMzMzMyIDYuOTk5NjdDMC4zMzMzMzIgMTAuNjc5NyAzLjMyIDEzLjY2NjMgNyAxMy42NjYzQzEwLjY4IDEzLjY2NjMgMTMuNjY2NyAxMC42Nzk3IDEzLjY2NjcgNi45OTk2N0MxMy42NjY3IDMuMzE5NjcgMTAuNjggMC4zMzMwMDggNyAwLjMzMzAwOFpNNyAxMi4zMzNDNC4wNiAxMi4zMzMgMS42NjY2NyA5LjkzOTY3IDEuNjY2NjcgNi45OTk2N0MxLjY2NjY3IDQuMDU5NjcgNC4wNiAxLjY2NjM0IDcgMS42NjYzNEM5Ljk0IDEuNjY2MzQgMTIuMzMzMyA0LjA1OTY3IDEyLjMzMzMgNi45OTk2N0MxMi4zMzMzIDkuOTM5NjcgOS45NCAxMi4zMzMgNyAxMi4zMzNaTTEwLjUzMTcgNC41MjgwMUMxMC4yNzE0IDQuMjY1OTUgOS44NDc4MSA0LjI2NTIgOS41ODY2NiA0LjUyNjM0TDUuNjY2NjcgOC40NDYzNEw0LjQxMDQ1IDcuMTk0OThDNC4xNTA0OCA2LjkzNjAxIDMuNzI5OTIgNi45MzY0MiAzLjQ3MDQ1IDcuMTk1ODlDMy4yMTA2MyA3LjQ1NTcxIDMuMjEwNjMgNy44NzY5NyAzLjQ3MDQ1IDguMTM2OEw0Ljk1OTU2IDkuNjI1OUM1LjM1MDA4IDEwLjAxNjQgNS45ODMyNSAxMC4wMTY0IDYuMzczNzcgOS42MjU5TDEwLjUzIDUuNDY5NjhDMTAuNzg5OCA1LjIwOTgzIDEwLjc5MDYgNC43ODg3NyAxMC41MzE3IDQuNTI4MDFaIiBmaWxsPSIjMzMzMzMzIiBmaWxsLW9wYWNpdHk9IjAuMjUiLz4KPC9zdmc+Cg=='
const IconMarSuccess =
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjMzMzMzMiA2Ljk5OTY3QzAuMzMzMzMyIDMuMzE5NjcgMy4zMiAwLjMzMzAwOCA3IDAuMzMzMDA4QzEwLjY4IDAuMzMzMDA4IDEzLjY2NjcgMy4zMTk2NyAxMy42NjY3IDYuOTk5NjdDMTMuNjY2NyAxMC42Nzk3IDEwLjY4IDEzLjY2NjMgNyAxMy42NjYzQzMuMzIgMTMuNjY2MyAwLjMzMzMzMiAxMC42Nzk3IDAuMzMzMzMyIDYuOTk5NjdaTTkuNTg2OTMgNC41MjYwOEw1LjY2NjY3IDguNDQ2MzRMNC40MTA0NSA3LjE5NDk4QzQuMTUwNDggNi45MzYwMSAzLjcyOTkyIDYuOTM2NDIgMy40NzA0NSA3LjE5NTg5QzMuMjEwNjMgNy40NTU3MSAzLjIxMDYzIDcuODc2OTcgMy40NzA0NSA4LjEzNjhMNS4xOTUyNiA5Ljg2MTYxQzUuNDU1NjEgMTAuMTIyIDUuODc3NzIgMTAuMTIyIDYuMTM4MDcgOS44NjE2MUwxMC41MzAzIDUuNDY5NDJDMTAuNzkgNS4yMDk3MiAxMC43OTA0IDQuNzg4NjMgMTAuNTMxNyA0LjUyODAyQzEwLjI3MTYgNC4yNjYwOSA5Ljg0NzkzIDQuMjY1MDggOS41ODY5MyA0LjUyNjA4WiIgZmlsbD0iIzAwMTlGRiIvPgo8L3N2Zz4K'

export const Container = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 0;
    padding-left: 0;
`

export const ListItem = styled.li<{ successfully: boolean }>`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    ${({ successfully }) =>
        successfully
            ? css`
                  color: rgba(0, 25, 255, 1);
              `
            : css`
                  color: rgba(51, 51, 51, 0.5);
              `}
`
