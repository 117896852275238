import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { CustomInput } from 'components/atoms'
import { DescriptionAuth, TitleAuth } from 'containers/Auth/styles'
import { CHECK_EMAIL_SEARCH_TYPE } from 'containers/Auth/types'
import { useAuth, usePreloader } from 'context'
import { getErrorMessage } from 'helpers'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled, ButtonString } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

import { BlueArrowLeft } from '../../styles'

const PasswordRecovery = () => {
    const { t } = useTranslation('translation')
    const [emailInput, setEmailInput] = useState('')
    const { showPreloader } = usePreloader()
    const { forgotUserPassword } = useAuth()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleSendEmail = async () => {
        showPreloader(true)

        try {
            await forgotUserPassword(emailInput)
            navigate({
                pathname: AUTH_ROUTES.CHECK_EMAIL,
                search: `?email=${emailInput}&type=${CHECK_EMAIL_SEARCH_TYPE.PASSWORD}`,
            })
        } catch (err) {
            dispatch(messageActions.messageShown({ text: getErrorMessage(err), severity: 'error' }))
        }
        showPreloader(false)
    }

    return (
        <>
            <TitleAuth>{t('Password reset')}</TitleAuth>
            <DescriptionAuth className="margin_bottom_32">{t('Enter the email you use for yoffix')}</DescriptionAuth>
            <CustomInput
                name="email"
                type="email"
                label={t('Email')}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSendEmail()
                    }
                }}
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
            />
            <ButtonFilled className="margin_bottom_16 margin_top_4" width="100%" onClick={handleSendEmail}>
                {t('Continue')}
            </ButtonFilled>
            <Link to={AUTH_ROUTES.LOGIN}>
                <ButtonString className="margin_left_content login_small">
                    <BlueArrowLeft />
                    {t('Back to Log in')}
                </ButtonString>
            </Link>
        </>
    )
}

export default PasswordRecovery
