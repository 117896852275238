import React, { FC, createContext, useState, useContext } from 'react'

import ProgressBar from 'components/atoms/ProgressBar'
import { noop } from 'helpers'

const PreloaderContext = createContext<{
    showPreloader: (state: boolean) => void
    setValueProgressBar: (value: number) => void
}>({
    showPreloader: noop,
    setValueProgressBar: noop,
})

const defaultPreloaderState = { isLoad: false, progressValue: null as number | null }

export const PreloaderProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(defaultPreloaderState)

    const setValueProgressBar = (value: number) => {
        setState({ progressValue: value, isLoad: true })
    }

    const showPreloader = (isLoad: boolean) => {
        setState({ isLoad, progressValue: null })
    }

    return (
        <PreloaderContext.Provider value={{ showPreloader, setValueProgressBar }}>
            {state.isLoad && <ProgressBar value={state.progressValue} />}
            {children}
        </PreloaderContext.Provider>
    )
}

export const usePreloader = () => useContext(PreloaderContext)
