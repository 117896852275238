import styled, { css } from 'styled-components'

import { AlertTitle, AlertDescription } from 'styles/alert-styles'

export const AlertContentWrapper = styled.div<{ hasIcon?: boolean }>`
    ${(props) =>
        props.hasIcon &&
        css`
            margin-top: 12px;
            display: flex;
            align-items: center;
            flex-direction: column;
        `}

    #alert-dialog-title {
        ${(props) =>
            props.hasIcon &&
            css`
                text-align: center;
                margin: 20px 0 0;
            `}
    }
`

export const AlertTitleWrapp = styled(AlertTitle)`
    &.MuiDialogTitle-root {
        white-space: pre-line;
        margin-bottom: 8px;
        text-align: center;
    }
`

export const AlertDialogActions = styled.div<{ direction: 'column' | 'row' }>`
    padding: 0;
    display: flex;
    flex-direction: ${(props) => props.direction};
    margin-top: 24px;

    button {
        margin: auto;
        width: 200px;
        height: 41px;

        ${(props) =>
            props.direction === 'column' &&
            css`
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            `}

        ${(props) =>
            props.direction === 'row' &&
            css`
                &:not(:last-child) {
                    margin-right: 10px;
                }
            `}
    }
`

export const AlertDescriptionWrapp = styled(AlertDescription)`
    margin: 8px 0 0;
    word-break: break-word;
`

export const AlertInfoWrapp = styled.div`
    width: 358px;
    background-color: ${(props) => props.theme.color.greyBackground};
    padding: 24px;
    border-radius: 20px;
    color: ${(props) => props.theme.color.black};

    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -24px);
`

export const CloseButtonWrapp = styled.div`
    position: absolute;
    top: 14px;
    right: 14px;
`
