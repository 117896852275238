import React, { ReactNode } from 'react'

import { Container, Title } from './styles'

interface PasswordHintProps {
    hintRequirements: ReactNode
    safetyAssessment: ReactNode
}
const PasswordHint = ({ hintRequirements, safetyAssessment }: PasswordHintProps) => {
    return (
        <Container>
            <Title>Your password must contain:</Title>
            {hintRequirements}
            {safetyAssessment}
        </Container>
    )
}

export default PasswordHint
