import React, { FC } from 'react'

import { CrossSVG } from 'assets/common'

import { CloseBtnRoot } from './styles'

type Props = {
    onClose: () => void
    size?: number
}

const CloseBtn: FC<Props> = ({ onClose, size = 14 }) => {
    return (
        <CloseBtnRoot onClick={onClose} className="close-btn">
            <CrossSVG width={size} height={size} />
        </CloseBtnRoot>
    )
}

export default CloseBtn
