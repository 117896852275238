import styled from 'styled-components'

import { CrossSVG } from 'assets/common'

export const SubscribePaywallShadowWrapper = styled.div`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 1300;
    padding: 8px;
`

export const BigSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
`

export const SubscribePaywallContentRoot = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.color.white};
    z-index: 1301;
    border-radius: 20px;
    display: flex;
`

export const SubscribePaywallRelativeWrapp = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 16px 18px 40px;
    overflow: auto;
`

export const SubscribePaywallCloseBtn = styled(CrossSVG)`
    width: 14px;
    height: 14px;
    fill-opacity: 1;
    position: absolute;
    top: 14px;
    right: 18px;
    cursor: pointer;
`

export const SubscribePaywallInsideWrapp = styled.div`
    display: flex;
    margin-inline: auto;
    flex-direction: column;
`

export const SubscribePaywallTitleWrapper = styled.div`
    margin-bottom: 40px;
`

export const SubscribePaywallTitle = styled.h1`
    padding: 0;
    margin: 0 0 8px;
    font-weight: 600;
    color: #333333;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
`

export const SubscribePaywallDescription = styled.p`
    width: 434px;
    font-size: 14px;
    line-height: 19.6px;
    color: #333333;
    text-align: center;
    margin: auto;
`
