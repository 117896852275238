import { useCallback, useRef, MouseEvent, TouchEvent } from 'react'

import { checkExistsFunction } from 'helpers/utils'

type Events = MouseEvent | TouchEvent
//todo: here typing(remove any)
type EventHandlerType = (e: Events, ...args: any[]) => void
type LongPressOptions<T> = { delay?: number; blocked?: T }
type HandlerType<T, E> = T extends boolean ? (e: E, ...args: any[]) => void : undefined

type LongPressReturnType<Blocked> = {
    onMouseDown: HandlerType<Blocked, MouseEvent>
    onMouseUp: HandlerType<Blocked, MouseEvent>
    onMouseLeave: HandlerType<Blocked, MouseEvent>
    onTouchStart: HandlerType<Blocked, TouchEvent>
    onTouchEnd: HandlerType<Blocked, TouchEvent>
}

type UseLongPressArgs<O> = {
    onLongPress: EventHandlerType
    onClick?: EventHandlerType
    options?: LongPressOptions<O>
}

const useLongPress = <O extends boolean = false>({
    onLongPress,
    onClick,
    options: { delay, blocked } = { delay: 300 },
}: UseLongPressArgs<O>): LongPressReturnType<O> => {
    const longPressTriggered = useRef(false)
    const timeout = useRef<NodeJS.Timeout>()
    const target = useRef<EventTarget>()

    const start = useCallback(
        (event: Events, args) => {
            if (event.target) {
                event.target.addEventListener('touchend', ((e: Events) => eventCallback(e)) as () => void, {
                    passive: false,
                })
                target.current = event.target
            }

            timeout.current = setTimeout(() => {
                onLongPress(event, ...args)
                longPressTriggered.current = true
            }, delay)
        },
        [onLongPress, delay],
    )

    const clear = useCallback(
        (event: Events, shouldTriggerClick: boolean, args) => {
            timeout.current && clearTimeout(timeout.current)
            longPressTriggered.current = false

            if (shouldTriggerClick && !longPressTriggered.current) {
                checkExistsFunction(onClick)(event, ...args)
            }

            if (target.current) {
                target.current.removeEventListener('touchend', ((e: Events) => eventCallback(e)) as () => void)
            }
        },
        [onClick],
    )

    return {
        onMouseDown: !blocked ? (e, ...args) => start(e, args) : undefined,
        onMouseUp: !blocked ? (e, ...args) => clear(e, true, args) : undefined,
        onMouseLeave: !blocked ? (e, ...args) => clear(e, false, args) : undefined,
        onTouchStart: !blocked ? (e, ...args) => start(e, args) : undefined,
        onTouchEnd: !blocked ? (e, ...args) => clear(e, true, args) : undefined,
    } as LongPressReturnType<O>
}

const eventCallback = (event: Events) => {
    if (!('touches' in event)) {
        return
    }

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault()
    }
}

export default useLongPress
