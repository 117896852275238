import styled, { css } from 'styled-components'

const widthChartPopup = 176
const offsetChartPopup = 30

export const DashboardRoot = styled.div`
    width: 100%;
    min-width: 320px;
    margin: 24px 0 16px;
`

export const DashboardRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-right: 0;

    & > div:not(:last-child) {
        margin-right: 16px;
    }

    &.full-media-1440 {
        @media (min-width: 1280px) {
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &:not(.full-media-1440) {
        @media (min-width: 1024px) {
            justify-content: space-between;
            flex-direction: row;
        }
    }
`

export const DashboardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
`

export const GridItem = styled.div`
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
`

export const DashboardHalfRow = styled.div`
    & > div {
        width: 100%;

        @media (min-width: 1440px) {
            width: 50%;
        }
    }
`

export const ChartPopupContainer = styled.div<{ open: boolean; top: number; left: number }>`
    width: ${widthChartPopup + 'px'};
    padding: 6px 8px 8px;
    position: absolute;
    border-radius: 8px;
    background: #ffffff;

    opacity: ${(props) => (props.open ? '1' : '0')};
    transition: opacity 0.2s ease-out;
    top: ${(props) => props.top + 10 + 'px'};

    ${(props) =>
        props.left + widthChartPopup + offsetChartPopup > window.innerWidth
            ? css`
                  right: ${offsetChartPopup + 'px'};
              `
            : css`
                  left: ${props.left + 10 + 'px'};
              `}
`

export const LegendDate = styled.div`
    //margin-bottom: 6px;
`

export const LegendRow = styled.div`
    display: flex;
    margin-top: 8px;
    margin-bottom: 2px;
    align-items: center;
`

export const LegendName = styled.div`
    flex: 1;
    color: rgba(51, 51, 51, 0.5);
    margin-left: 8px;
`

export const LegendColor = styled.div<{ color: string }>`
    width: 12px;
    height: 12px;

    border-radius: 50%;
    border: 1px solid #333333;
    background: ${(props) => props.color};
`

export const SVGRootContainer = styled.div<{ height?: number }>`
    position: relative;
    height: ${(props) => props.height || 256}px;

    .x-axis-labels {
        position: absolute; /* Position the labels absolutely */
        bottom: 0; /* Fix the labels at the bottom */
        width: 100%;
        height: 20px; /* Adjust based on your needs */
        pointer-events: none; /* Ensure it doesn't interfere with the SVG */
    }

    .x-axis-labels div {
        position: absolute;
        transform: translateX(-50%); /* Center the labels */
        white-space: nowrap;
    }
`

export const SVGRoot = styled.svg<{ height?: number }>`
    width: 100%;
    height: ${(props) => props.height || 256}px;

    text {
        color: rgba(51, 51, 51, 0.5);
        font-size: 14px;
    }

    & .leftLabel {
        font-size: 16px;
        color: #333;
    }
`

export const SVGRootFloat = styled.svg<{ height?: number }>`
    width: 100%;

    text {
        color: rgba(51, 51, 51, 0.5);
        font-size: 14px;
    }

    & .leftLabel {
        font-size: 16px;
        color: #333;
    }
`
export const XAxisContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 256px; /* Fixed height for the x-axis container */
    pointer-events: none; /* Ensure it doesn't interfere with the SVG */

    text {
        color: rgba(51, 51, 51, 0.5);
        font-size: 14px;
    }
`

export const RootWrap = styled.div`
    background: ${(props) => props.theme.color.greyBackground50};
    border-radius: 20px;
    padding: 24px;
    width: 100%;

    &.table_header {
        background: #e7ebf1;
        border-radius: 20px 20px 0 0;
        margin-bottom: 0px;
        padding-bottom: 16px;
    }

    &.table_body {
        border-radius: 0 0 20px 20px;
        height: 258px;
        overflow-y: hidden;
        padding-top: 16px;
        padding-bottom: 8px;
    }

    & .custom-vertical-scroll .simplebar-track.simplebar-vertical {
        right: -10px;
    }
`

export const DateText = styled.div`
    flex: 1;
    margin: 0 8px;
    font-size: 16px;
`

export const OfficeDropDownWrap = styled.div`
    width: 155px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
`

export const SettingsWrap = styled.div`
    display: flex;
    align-items: center;
    //margin-bottom: 24px;
`

export const ChartTitle = styled.h2`
    width: 100%;
    text-align: start;
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    margin: 0 0 24px;
`

export const ChartTitleWithDropDown = styled.h2`
    width: 100%;
    text-align: start;
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    margin: 0 0 24px;
    display: flex;
    justify-content: space-between;
`
