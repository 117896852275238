import styled from 'styled-components'

export const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: auto;
`

export const AuthLogo = styled.img`
    width: 80px;
`
