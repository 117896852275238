import styled, { css } from 'styled-components'

export const HeaderInterfaceWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 16px;

    & > div:last-child {
        margin-left: 16px;
    }
`

export const HeaderInterfaceTodayBtn = styled.span<{ disabled?: boolean }>`
    height: 32px;
    border-radius: 12px;
    border: 1px solid ${(props) => props.theme.color.greyBackground};
    background-color: ${(props) => props.theme.color.white};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    width: max-content;
    align-items: center;
    color: rgba(51, 51, 51, 0.5);
    padding: 0 12px;

    ${(props) =>
        !props.disabled &&
        css`
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }
        `};
`
