import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const DropDownIconContainer = styled(IconButton)`
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    right: 4px;
    cursor: pointer;
    box-sizing: content-box;
    pointer-events: none;

    svg {
        width: 24px;
        height: 24px;
    }
`
