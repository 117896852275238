import React, { FC } from 'react'

import { TagContainer, TagAmountWrapp, TagText } from './styles'

export enum TAG_TYPES {
    GREEN = '#A4EFC1',
    RED = '#FDB5B5',
    YELLOW = '#FFD84D',
    WHITE = '#F1F4F7',
    NEGATIVE_RED = '#FF6868',
    POSITIVE_GREEN = '#1BD665',
}

type Props = {
    text: string
    amount?: number
    type: TAG_TYPES
}

const Tag: FC<Props> = ({ text, amount, type }) => {
    return (
        <TagContainer style={{ backgroundColor: type }}>
            {amount && <TagAmountWrapp>{amount}</TagAmountWrapp>}
            <TagText>{text}</TagText>
        </TagContainer>
    )
}

export default Tag
