import * as microsoftTeams from '@microsoft/teams-js'

const isInMsTeams = (): boolean => {
    // eslint-disable-next-line dot-notation
    const microsoftTeamsLib = microsoftTeams || window['microsoftTeams']

    if (!microsoftTeamsLib) {
        return false // the Microsoft Teams library is for some reason not loaded
    }

    return !!(
        (window.parent === window.self && window.nativeInterface) ||
        window.name === 'embedded-page-container' ||
        window.name === 'extension-tab-frame'
    )
}

export default isInMsTeams
