import { Dispatch, SetStateAction } from 'react'

import { BookingType as RealBookingType, RepeatType } from 'graphql/autogenerate/schemas'

import { TEmployee, TSpace } from './data.types'
import { PaddleType } from './paddle'

export type TImageSlider = {
    url: string
    key: string
}

export type NextToken = string | null

export type MobileScheduleRepeatType = RepeatType

export type TPhotoLoaded = {
    src: string
    file: File | null
    fileName?: string
    originalFileName?: string
}

export type TAddressGoogle = {
    city?: string
    country?: string
    address?: string
    zipcode?: string
    lat?: number
    long?: string
    str?: string
}

// todo remove
export type TAddOffcePopup = {
    open: boolean
    spaceData: TSpace | null
}

export type TAddEmployeePopup = {
    open: boolean
    employeeData: TEmployee | null
}

export type TConfirmPopup = {
    open: boolean
    type: 'delete' | 'create' | 'update' | 'deleteAll' | null
    id: string | string[] | null
    name: string
}

declare global {
    interface Window {
        Paddle: PaddleType
        dataLayer?: any
        nativeInterface?: any
    }
}

export type HistoryState = {
    new?: boolean
}

export type LocationInitState = {
    pathname: string
    search: string
}

export type DatePaginationBtnsType = 'prev' | 'next'

export type StateSetter<T> = Dispatch<SetStateAction<T>>

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never
export type InferActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesTypes<T>>

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export type Parameters<P> = P extends (args: infer D) => any ? D : never

export type MultiselectItem = string | { id: string; name: string }

export type MultiselectObjectItem = { id: string; name: string }

export enum BookingType {
    OFFICE = 'OFFICE',
    REMOTE = 'REMOTE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    TEAM_EVENT = 'TEAM_EVENT',
    ROOM = 'ROOM',
}

export enum BookingSubType {
    VACATION = 'VACATION',
    SICK_LEAVE = 'SICK_LEAVE',
    BUSINESS_TRIP = 'BUSINESS_TRIP',
}

export type CreateRecurringBookingInput = {
    id: string
    companyID: string
    daysOfWeek: Array<string> | null
    employeeID: string
    reservableID: string | null
    repeatType: MobileScheduleRepeatType | null
    spaceID: string | null
    effectiveFrom: string
    startTime: string
    endTime: string
    bookingRequestReservableId: string | null
    previousBookingRequestID: string | null
    addParkingSlot: boolean | null
    owner: string
    bookingType: RealBookingType
    bookingSubType: BookingSubType | null
    parkingSlotID?: string
    dates: Array<string> | null
}
