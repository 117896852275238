import { FC } from 'react'

import { EmployeeItemRoot, LoadingItem, LoadingItemWrap } from './styles'

type Props = {
    size: number
}

const LoadingEmployeeItem: FC<Props> = ({ size }) => {
    return (
        <EmployeeItemRoot>
            <div className="df">
                <LoadingItem width={`${size}px`} height={`${size}px`} boderRadius="50%" />
                <LoadingItemWrap>
                    <LoadingItem width={`${size * 3}px`} height={`${size / 3}px`} boderRadius={`${size / 8}px`} />
                    <LoadingItem width={`${size * 2}px`} height={`${size / 4}px`} boderRadius={`${size / 8}px`} />
                </LoadingItemWrap>
            </div>
        </EmployeeItemRoot>
    )
}

export default LoadingEmployeeItem
