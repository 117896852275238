import { useEffect, useState } from 'react'

import { checkExistsFunction } from 'helpers/utils'

const useKeyPress = (targetKey: string, upCallback?: () => void) => {
    const [keyPressed, setKeyPressed] = useState(false)

    useEffect(() => {
        function downHandler(ev: KeyboardEvent) {
            if (ev.key === targetKey) {
                setKeyPressed(true)
            }
        }

        const upHandler = (ev: KeyboardEvent) => {
            if (ev.key === targetKey) {
                setKeyPressed(false)
                checkExistsFunction(upCallback)()
            }
        }

        window.addEventListener('keydown', downHandler)
        window.addEventListener('keyup', upHandler)

        return () => {
            window.removeEventListener('keydown', downHandler)
            window.removeEventListener('keyup', upHandler)
        }
    }, [targetKey, upCallback])

    return keyPressed
}

export default useKeyPress
