import * as d3 from 'd3'
import { setISOWeek, setYear, startOfISOWeek, addWeeks, setISOWeekYear, setISODay, subHours } from 'date-fns'
import moment, { Moment } from 'moment'

export const getPercent = (value1?: number, value2?: number) => {
    if (!value1 || !value2) {
        return 0
    }
    if (value2 === 0) {
        return 0
    }

    return Math.round((value1 / value2) * 100)
}

export const getDateByWeekYear = (week?: number, year?: number) => {
    return moment()
        .set({ year })
        .isoWeek(week || 0)
        .isoWeekday(4)
        .subtract(12, 'hours')
}

export function getDateByWeekYear2(week?: number, year?: number): Date {
    // Start from January 4th of the specified year (always in ISO week 1)
    let date = new Date(year!, 0, 4)
    date = setISOWeekYear(date, year!)
    date = setISOWeek(date, week || 0)
    date = setISODay(date, 4) // ISO weekday 4 is Thursday
    date = subHours(date, 12)
    return date
}

export const getRangeWeekDateString = (date: Moment) => {
    return `${date.isoWeekday(1).format('DD MMM')} - ${date.isoWeekday(7).format('DD MMM')}`
}

// Helper function to truncate text based on width (85px)
export const truncateText = (selection: d3.Selection<d3.BaseType, any, SVGGElement, unknown>, maxWidth: number) => {
    selection.each(function () {
        const self = d3.select(this as SVGTextElement) // Cast this to SVGTextElement
        const text = self.text()
        const textElement = this as SVGTextElement // Ensure this is an SVGTextElement
        const textWidth = textElement.getComputedTextLength()

        if (textWidth > maxWidth) {
            // Determine how much of the text we can fit
            const ellipsis = '...'
            let truncatedText = text

            // Try reducing the text and adding ellipsis
            while (truncatedText.length > 0 && textElement.getComputedTextLength() > maxWidth - 3) {
                truncatedText = truncatedText.slice(0, -1)
                self.text(truncatedText + ellipsis)
            }
        }
    })
}

export function hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
    // Convert HSL to RGB
    s /= 100
    l /= 100

    const k = (n: number) => (n + h / 30) % 12
    const a = s * Math.min(l, 1 - l)
    const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)))

    return { r: Math.round(255 * f(0)), g: Math.round(255 * f(8)), b: Math.round(255 * f(4)) }
}

export function rgbToHex(r: number, g: number, b: number): string {
    return (
        '#' +
        [r, g, b]
            .map((x) => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
            })
            .join('')
    )
}

export function rgbToRgbaString(r: number, g: number, b: number, a: number): string {
    return `rgba(${r}, ${g}, ${b}, ${a})`
}
