import styled from 'styled-components'

export const CustomSwitchRoot = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;

    & .MuiSwitch-root {
        width: 22px;
        height: 14px;
        padding: 0;
    }

    & .MuiSwitch-switchBase {
        padding: 3px;
        color: black;

        &.Mui-checked {
            transform: translate(8px);
            color: ${(props) => props.theme.color.white};

            & + .MuiSwitch-track {
                opacity: 1;
                background-color: ${(props) => props.theme.color.activeSpacePanel};
                border-color: ${(props) => props.theme.color.activeSpacePanel};
            }
        }
    }

    & .MuiSwitch-thumb {
        width: 8px;
        height: 8px;
        box-shadow: none;
    }

    & .MuiSwitch-track {
        border: 2px solid rgba(51, 51, 51, 0.5);
        border-radius: 8px;
        opacity: 1;
        background-color: ${(props) => props.theme.color.white};
    }

    label {
        margin-left: 16px;

        ${(props) =>
            props.disabled &&
            `
                color: rgba(51, 51, 51, 0.5);
            `}
    }
`

export const CustomMediumSwitchRoot = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;

    & .MuiSwitch-root {
        width: 36px;
        height: 23px;
        padding: 0;
    }

    & .MuiSwitch-switchBase {
        padding: 5px;
        color: black;

        &.Mui-checked {
            transform: translate(13px);
            color: ${(props) => props.theme.color.white};

            & + .MuiSwitch-track {
                opacity: 1;
                background-color: ${(props) => props.theme.color.activeSpacePanel};
                border-color: ${(props) => props.theme.color.activeSpacePanel};
            }
        }
    }

    & .MuiSwitch-thumb {
        width: 13px;
        height: 13px;
        box-shadow: none;
    }

    & .MuiSwitch-track {
        border: 2px solid rgba(51, 51, 51, 0.5);
        border-radius: 61px;
        opacity: 1;
        background-color: ${(props) => props.theme.color.white};
    }

    label {
        margin-left: 16px;

        ${(props) =>
            props.disabled &&
            `
                color: rgba(51, 51, 51, 0.5);
            `}
    }
`
