import styled from 'styled-components'

import RightArrow from 'assets/icons/right-arrow-24.svg'
import { ButtonString } from 'styles/buttons-styles'

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
`

export const ForgotPasswordBtn = styled(ButtonString)`
    margin-bottom: 16px;
`

export const TitleAuthSignIn = styled.h1`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    margin: 32px 0 32px 0;
    color: rgba(51, 51, 51, 1);
`

export const TitleAuth = styled.h1`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    margin: 32px 0 12px 0;
    color: rgba(51, 51, 51, 1);
`

export const LoginORField = styled.div`
    font-size: 16px;
    margin: 8px 0;
    text-align: center;
    color: rgba(51, 51, 51, 0.5);
`

export const InputRowWrapp = styled.div`
    display: flex;

    & > div:not(:last-child) {
        margin-right: 8px;
    }
`

export const DescriptionAuth = styled.p`
    margin: 0 0 24px;

    &.info_gray {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.5);
    }
`

export const BlueArrowLeft = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${RightArrow});
    background-position: center center;
    background-size: contain;
    margin-right: 5px;
    transform: rotate(180deg);
`

export const BlueArrowRight = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${RightArrow});
    background-position: center center;
    background-size: contain;
    margin-right: 5px;
`
