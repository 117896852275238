import { AlertProps } from '@material-ui/lab/Alert/Alert'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type MessageStateType = {
    text: string
    severity: AlertProps['severity']
}

const initialState: MessageStateType = {
    text: '',
    severity: 'info',
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        messageShown: (_, action: PayloadAction<MessageStateType>) => action.payload,
        messageClosed: () => initialState,
    },
})

export const messageActions = messageSlice.actions
export default messageSlice.reducer
