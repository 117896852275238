import React from 'react'

import CapterraLogo from 'assets/auth/Capterra/CapterraLogo.svg'

import CapterraStars from './Stars'
import { CapterraContainer, CapterraItem, CapterraText } from './styles'

const CapterraRate = () => {
    return (
        <CapterraContainer>
            <CapterraItem>
                <img src={CapterraLogo} alt="Capterra logo" />
            </CapterraItem>
            <CapterraItem>
                <CapterraText>Ease of use</CapterraText>
                <CapterraStars />
            </CapterraItem>
            <CapterraItem>
                <CapterraText>Value for money</CapterraText>

                <CapterraStars />
            </CapterraItem>
            <CapterraItem>
                <CapterraText>Customer service</CapterraText>
                <CapterraStars />
            </CapterraItem>
        </CapterraContainer>
    )
}

export default CapterraRate
