import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const DropDownIconContainer = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    box-sizing: content-box;
    pointer-events: none;

    svg {
        width: 16px;
        height: 16px;
    }
`
