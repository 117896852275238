import React, { FC, Fragment } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import TooltipContainer from 'components/TooltipContainer'

import { SidebarList, SidebarListItem, SidebarSeparator, TooltipProps } from '../styles'
import { RouteLink, SidebarLinks } from '../types'

type Props = {
    links: SidebarLinks
    pathname: string
}

const NavbarLinks: FC<Props> = ({ pathname, links }) => {
    const { t } = useTranslation('translation')
    const navBars = Object.keys(links).map((item) => {
        const link = links[item] as RouteLink

        if (link !== null) {
            return (
                <Fragment key={link.page}>
                    {link.page === 'Offices' && <SidebarSeparator />}
                    <TooltipContainer title={t(link.page) as string} {...TooltipProps}>
                        <Link
                            to={`/${link.page.toLowerCase().replace(' ', '-')}`}
                            data-intercom-target={link.page}
                            role="button"
                        >
                            <SidebarListItem
                                isActive={pathname.replace('-', ' ').includes(link.page.toLowerCase())}
                                className="flex-center"
                            >
                                {link.icon}
                            </SidebarListItem>
                        </Link>
                    </TooltipContainer>
                </Fragment>
            )
        }
    })

    return <SidebarList className="main-navigation flex-center">{navBars}</SidebarList>
}

export default NavbarLinks
