import styled from 'styled-components'

import { ButtonOutlined } from '../../../../styles/buttons-styles'

export const TrialExpiredContainer = styled.div`
    position: absolute;
    bottom: 10px;
    padding: 10px 12px 10px 16px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.color.greyBackground50};
    background: ${(props) => props.theme.color.white_80};
    box-shadow: 0px 4px 8px -5px rgba(151, 157, 164, 0.5);
    backdrop-filter: blur(12.5px);
    z-index: 9999999999999;
    left: 50%;
    transform: translateX(-40%);
    @media (min-width: 480px) and (max-width: 1090px) {
        left: 25%;
        transform: translateX(0);
    }
`
export const TrialExpiredTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: ${(props) => props.theme.color.secondary_black_50};
    b {
        color: ${(props) => props.theme.color.black};
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }
`
export const TrialExpiredButton = styled.a`
    display: flex;
    padding: 10px 20px 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #ff5525;
    color: ${(props) => props.theme.color.main_white_90};
    font-size: 1rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    height: auto;

    &:hover {
        background: #fc4b17;
    }

    .phone-svg {
        path {
            fill: white;
        }
    }
`

export const TrialExpiredButtonClose = styled(ButtonOutlined)`
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 8px;
    height: auto;
    border: transparent;
    &:hover {
        background: ${(props) => props.theme.color.greyBackground50};
    }
    .close-svg {
        path {
            fill: #333333;
            fill-opacity: 0.5;
        }
    }
`
