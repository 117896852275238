import React, { FC } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput } from 'components/atoms'
import { TitleAuth, TitleWrapper } from 'containers/Auth/styles'
import { CHECK_EMAIL_SEARCH_TYPE } from 'containers/Auth/types'
import { usePreloader } from 'context'
import { useAuth } from 'context/auth'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { ButtonFilled, ButtonString } from 'styles/buttons-styles'
import { AUTH_ROUTES } from 'types/routes'

import { BlueArrowLeft } from '../../styles'

const schema = yup.object().shape({
    email: yup.string().required('Email is required!').email('Email is invalid!'),
    password: yup.string().required('Password is required!'),
})

type SignUpFormInputs = {
    email: string
    password: string
}

const SignUp: FC = () => {
    const { t } = useTranslation('translation')
    const { signUp } = useAuth()
    const { showPreloader } = usePreloader()
    const navigate = useNavigate()

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm<SignUpFormInputs>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const handleCreateAccount = async (dataSubmit: SignUpFormInputs) => {
        showPreloader(true)

        try {
            await signUp({
                username: dataSubmit.email,
                password: dataSubmit.password,
                attributes: {
                    name: '',
                    email: dataSubmit.email,
                    family_name: '',
                    'custom:isCompanyCreated': 'false',
                    'custom:companyId': 'id',
                },
            })

            analytics.logEvent(AnalyticsEvents.FREE_TRIAL_STARTED)

            if (window.dataLayer) {
                window.dataLayer.push({ event: 'free-trial-started' })
            }

            navigate({
                pathname: AUTH_ROUTES.CHECK_EMAIL,
                search: `?email=${dataSubmit.email}&type=${CHECK_EMAIL_SEARCH_TYPE.REGISTER}`,
            })
        } finally {
            showPreloader(false)
        }
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleCreateAccount)()
        }
    }

    return (
        <>
            <TitleWrapper>
                <TitleAuth className="margin_bottom_0" dangerouslySetInnerHTML={{ __html: t('Register as company') }} />
                <Link to={AUTH_ROUTES.LOGIN}>
                    <ButtonString className="login_small">
                        <BlueArrowLeft />
                        {t('Log in')}
                    </ButtonString>
                </Link>
            </TitleWrapper>
            <form>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="email"
                            label={t('Email')}
                            helperText={errors.email?.message}
                            error={!!errors.email?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="password"
                            label={t('Password')}
                            helperText={errors.password?.message}
                            error={!!errors.password?.message}
                            onKeyPress={handleKeyPressed}
                            autoComplete="off"
                        />
                    )}
                />

                <ButtonFilled
                    className="margin_top_16"
                    width="100%"
                    type="button"
                    onClick={handleSubmit(handleCreateAccount)}
                >
                    {t('Continue')}
                </ButtonFilled>
            </form>
        </>
    )
}

export default SignUp
