import styled from 'styled-components'

export const TrustedByContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`

export const TrustedByLogosContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TrustedByText = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(49, 49, 57, 0.5);
`
