import React, { FC } from 'react'

import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { CustomInput } from 'components/atoms'
import { AuthRouteWrapp } from 'components/molecules'
import { TitleAuth, InputRowWrapp } from 'containers/Auth/styles'
import { useCompanyData, usePreloader, useAuth } from 'context'
import { MUTATION_UPDATE_COMPANY, MUTATION_UPDATE_EMPLOYEE } from 'graphql/mutations'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ButtonFilled } from 'styles/buttons-styles'
import { ERoleEmployee, TCompany, TEmployee } from 'types/data.types'
import { MANAGER_ROUTES } from 'types/routes'

interface IFormInputs {
    companyName: string
    firstName: string
    lastName: string
    phoneNumber: string
    timeZone: string
    workingTime: string
}

const schema = yup.object().shape({
    companyName: yup.string().required('Company name is required!'),
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    phoneNumber: yup.string(),
})
const CompanyConfirmation: FC = () => {
    const { t } = useTranslation(['translation'])
    const { userAuth, updateUserAttribute } = useAuth()
    const navigate = useNavigate()

    const { update } = useCompanyData()
    const { showPreloader } = usePreloader()
    const dispatch = useAppDispatch()

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm<IFormInputs>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    })

    const [updateCompany] = useMutation<{ updateCompany: TCompany }, { input: TCompany }>(MUTATION_UPDATE_COMPANY)
    const [updateEmployee] = useMutation<TEmployee, { input: TEmployee }>(MUTATION_UPDATE_EMPLOYEE)

    const handleSetCompany = async (dataSubmit: IFormInputs) => {
        try {
            if (!userAuth) {
                return
            }

            showPreloader(true)
            await updateCompany({
                variables: {
                    input: {
                        id: userAuth.companyId,
                        title: dataSubmit.companyName,
                        phoneNumber: dataSubmit.phoneNumber,
                        companyLogo: null,
                        timeZone: dataSubmit.timeZone,
                        workingTime: dataSubmit.workingTime,
                    },
                },
            })

            await updateEmployee({
                variables: {
                    input: {
                        id: userAuth.employeeId || '',
                        email: userAuth.email,
                        companyID: userAuth.companyId,
                        employeeCompanyId: userAuth.companyId,
                        firstname: dataSubmit.firstName,
                        lastname: dataSubmit.lastName,
                        role: ERoleEmployee.OWNER,
                    },
                },
            })

            await update()
            await updateUserAttribute('custom:isCompanyCreated', 'true', 'isCompanyCreated')
            analytics.logEvent(AnalyticsEvents.COMPANY_REGISTERED)
            navigate(MANAGER_ROUTES.DASHBOARD)
        } catch (err) {
            dispatch(messageActions.messageShown({ text: t("Sorry, the company wasn't created!"), severity: 'error' }))
        }
        showPreloader(false)
    }

    const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleSetCompany)()
        }
    }

    return (
        <AuthRouteWrapp>
            <TitleAuth className="margin_left_content margin_bottom_40">{t('Please fill out the details')}</TitleAuth>
            <InputRowWrapp>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('First name')}
                            helperText={errors.firstName?.message}
                            error={!!errors.firstName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            type="text"
                            label={t('Last name')}
                            helperText={errors.lastName?.message}
                            error={!!errors.lastName?.message}
                            onKeyPress={handleKeyPressed}
                        />
                    )}
                />
            </InputRowWrapp>
            <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Company')}
                        helperText={errors.companyName?.message}
                        error={!!errors.companyName?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type="text"
                        label={t('Phone')}
                        helperText={errors.phoneNumber?.message}
                        error={!!errors.phoneNumber?.message}
                        onKeyPress={handleKeyPressed}
                    />
                )}
            />
            <ButtonFilled width="100%" onClick={handleSubmit(handleSetCompany)}>
                {t('Continue')}
            </ButtonFilled>
        </AuthRouteWrapp>
    )
}

export default CompanyConfirmation
