import styled from 'styled-components'

export const TooltipBtnClue = styled((props) => <div {...props} className={`${props.className} flex-center`} />)`
    padding: 4px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-left: 4px;
    height: 16px;
    font-size: 11px;
    letter-spacing: 0.5px;
`
