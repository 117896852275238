import React, { FC } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

import { useAppSelector } from 'hooks'

const Loader: FC = () => {
    const loaderState = useAppSelector((state) => state.loader)

    if (!loaderState) {
        return null
    }

    return (
        <ProgressBarRoot className="flex-center preloader">
            <CircularProgress />
        </ProgressBarRoot>
    )
}

export const ProgressBarRoot = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    z-index: 2000;
`

export default Loader
