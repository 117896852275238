import styled from 'styled-components'

export const LanguageSwitcherRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const LanguageSwitcherButton = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 8px;
    font-weight: 400;

    ${(props) =>
        props.isActive ? `color: ${props.theme.color.black};` : `color: ${props.theme.color.secondaryBlack};`}
`

export const Divider = styled.div`
    color: ${(props) => props.theme.color.secondaryBlack};
`
