import React, { FC } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SettingsSVG } from 'assets/common'
import { IconIntercom24SVG } from 'assets/icons'
import { LogoutSVG, YoffoxSVG } from 'assets/navigation'
import TooltipContainer from 'components/TooltipContainer'
import { UserAvatar } from 'components/atoms'
import { useAuth } from 'context'
import { useAppSelector } from 'hooks'
import { ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import NavbarLinks from './NavbarLinks'
import { employeeSidebarLinks, teamLeadSidebarLinks, sidebarLinks } from '../const'
import * as S from '../styles'
import { SidebarLinks } from '../types'

type Props = {
    pathname: string
    userGroup: EUserGroup
}
const DesktopSidebar: FC<Props> = ({ pathname, userGroup }) => {
    const { t } = useTranslation('translation')
    const { roomManagement, useOnlySpaceScreen } = useFlags()
    const { signOut } = useAuth()

    const userName = useAppSelector(({ user }) => user.fullName)

    let links: SidebarLinks = { ...sidebarLinks }

    if (userGroup === EUserGroup.EMPLOYEE) {
        links = employeeSidebarLinks
    }

    if (userGroup === EUserGroup.TEAM_LEAD) {
        links = teamLeadSidebarLinks
    }

    if (roomManagement) {
        links = {
            ...links,
            calendar: null,
        }
    }

    if (useOnlySpaceScreen) {
        links = {
            ...links,
            rooms: null,
            calendar: null,
        }
    }

    const handleSingOut = async () => {
        await signOut()
    }

    return (
        <S.SidebarRoot>
            <S.LogoWrapper>
                <YoffoxSVG />
            </S.LogoWrapper>
            <NavbarLinks pathname={pathname} links={links} />
            <S.SidebarList className="bottom-navigation">
                <S.SidebarListItem id="intercom-launcher" className="flex-center">
                    <IconIntercom24SVG fill="#ffffff" fillOpacity={0.3} />
                </S.SidebarListItem>
                <TooltipContainer key="Profile" title={userName} {...S.TooltipProps}>
                    <Link to={ROUTES.PROFILE} data-intercom-target="Profile" role="button">
                        <S.ProfileAvatarWrap isActive={false} className="flex-center">
                            <UserAvatar imgSize={40} />
                        </S.ProfileAvatarWrap>
                    </Link>
                </TooltipContainer>

                {userGroup === EUserGroup.MANAGER && (
                    <>
                        <TooltipContainer key="Settings" title={t('Settings') as string} {...S.TooltipProps}>
                            <Link to={ROUTES.SETTINGS} data-intercom-target="Settings" role="button">
                                <S.SidebarListItem isActive={pathname.includes('settings')} className="flex-center">
                                    <SettingsSVG fill="#ffffff" fillOpacity={0.3} />
                                </S.SidebarListItem>
                            </Link>
                        </TooltipContainer>
                    </>
                )}
                <TooltipContainer key="Log out" title={t('Log out') as string} {...S.TooltipProps}>
                    <S.SidebarListItem onClick={handleSingOut} className="flex-center">
                        <LogoutSVG />
                    </S.SidebarListItem>
                </TooltipContainer>
            </S.SidebarList>
        </S.SidebarRoot>
    )
}

export default DesktopSidebar
