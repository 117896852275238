import styled, { css } from 'styled-components'

export const ButtonStandard = styled.button<{ disable?: boolean; width?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 14px;
    line-height: 150%;
    cursor: pointer;
    outline: none;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
`

const disableStyles = `
    color: #a4adff;
    pointer-events: none;
    cursor: not-allowed; 
`

export const ButtonString = styled(ButtonStandard)<{ color?: string }>`
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;

    color: ${(props) => props.color || props.theme.color.activeSpacePanel};

    ${(props) =>
        props.disable &&
        css`
            ${disableStyles}
        `}

    &.login_small {
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
    }
`

export const ButtonOutlined = styled(ButtonStandard)`
    border: 1px solid ${(props) => props.theme.color.activeSpacePanel};
    border-radius: 16px;
    color: ${(props) => props.theme.color.activeSpacePanel};
    background-color: transparent;
    height: 48px;

    &:hover {
        //background-color: rgba(0, 25, 255, 0.05);
    }

    ${(props) =>
        props.disable &&
        css`
            border-color: #a4adff;
            ${disableStyles}
        `}
`

export const ButtonGreyOutlined = styled(ButtonOutlined)`
    border: 1px solid ${(props) => props.theme.color.greyBackground};
    border-radius: 12px;
    height: 34px;
    padding: 0 16px;
    color: rgba(51, 51, 51, 0.5);
    font-size: 14px;
    background: transparent;
    white-space: nowrap;

    &:hover {
        color: rgba(255, 255, 255, 0.9);
        background-color: ${(props) => props.theme.color.activeSpacePanel};
    }
`

export const ButtonFilled = styled(ButtonStandard)`
    border: none;
    border-radius: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    background-color: ${(props) => props.theme.color.activeSpacePanel};
    height: 48px;

    &:hover {
        background-color: #0016d8;
    }

    ${(props) =>
        props.disable &&
        css`
            background-color: #c9c9c9;
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
        `}
`

export const ButtonDanger = styled(ButtonStandard)`
    border: none;
    border-radius: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    background-color: #ff374c;
    height: 48px;

    &:hover {
        background-color: #ff6868;
    }

    ${(props) =>
        props.disable &&
        css`
            background-color: #a4adff;
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
        `}
`

export const ButtonDangerOutline = styled(ButtonOutlined)`
    border-radius: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.color.coral};
    border: 1px solid ${(props) => props.theme.color.coral};
    height: 48px;

    &:hover {
        //background-color: #ff6868;
    }

    ${(props) =>
        props.disable &&
        css`
            background-color: #a4adff;
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
        `}
`

export const LinkOutlined = styled.a<{ width?: string; height?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    border: 1px solid ${(props) => props.theme.color.black};
    border-radius: 16px;
    color: ${(props) => props.theme.color.black};

    font-weight: 500;
    cursor: pointer;

    svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    &:hover {
        background-color: #e6e6e6;
    }

    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}

    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
`

export const ButtonOrange = styled(ButtonStandard)`
    font-size: 16px;
    border: none;
    border-radius: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    background-color: ${(props) => props.theme.color.orange};
    height: 64px;

    &:hover {
        background-color: #e6441f;
    }

    ${(props) =>
        props.disable &&
        css`
            background-color: #c9c9c9;
            color: rgba(255, 255, 255, 0.5);
            pointer-events: none;
        `}
`
