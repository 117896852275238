import { FC, useEffect } from 'react'

import { CheckoutUrlType } from 'context/billing/billing.context.types'

type Props = {
    email: string
    override: string
    callback: (cancel?: boolean) => void
    type: CheckoutUrlType
}

const PaddleCheckout: FC<Props> = ({ email, override, callback, type }) => {
    useEffect(() => {
        if (email !== undefined) {
            const Paddle = window.Paddle

            Paddle.Checkout.open({
                override,
                email,
                successCallback: (data, err) => {
                    if (err) {
                        console.log('Paddle error', err)
                    } else {
                        console.log('Paddle success', data)
                        type !== 'update' && callback()
                    }
                },
                closeCallback: () => callback(true),
            })
        }
    }, [email, override, type])

    return null
}

export default PaddleCheckout
